import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3321a0e8 = () => interopDefault(import('../pages/cex/index.vue' /* webpackChunkName: "pages/cex/index" */))
const _2f681aec = () => interopDefault(import('../pages/confirm_email.vue' /* webpackChunkName: "pages/confirm_email" */))
const _30eb05f8 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _15ffea0a = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _78a2a20c = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))
const _b98e10c6 = () => interopDefault(import('../pages/tasks.vue' /* webpackChunkName: "pages/tasks" */))
const _01629460 = () => interopDefault(import('../pages/tasks/index.vue' /* webpackChunkName: "pages/tasks/index" */))
const _7b4a2ec8 = () => interopDefault(import('../pages/tasks/_id.vue' /* webpackChunkName: "pages/tasks/_id" */))
const _2a10d4a0 = () => interopDefault(import('../pages/cex/api.vue' /* webpackChunkName: "pages/cex/api" */))
const _312e99d7 = () => interopDefault(import('../pages/profile/add-wallets.vue' /* webpackChunkName: "pages/profile/add-wallets" */))
const _1dea3367 = () => interopDefault(import('../pages/profile/billing/index.vue' /* webpackChunkName: "pages/profile/billing/index" */))
const _69f63860 = () => interopDefault(import('../pages/profile/onboard.vue' /* webpackChunkName: "pages/profile/onboard" */))
const _5f11878e = () => interopDefault(import('../pages/profile/recharge.vue' /* webpackChunkName: "pages/profile/recharge" */))
const _4399aa72 = () => interopDefault(import('../pages/profile/billing/paid.vue' /* webpackChunkName: "pages/profile/billing/paid" */))
const _674362e1 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cex",
    component: _3321a0e8,
    name: "cex"
  }, {
    path: "/confirm_email",
    component: _2f681aec,
    name: "confirm_email"
  }, {
    path: "/login",
    component: _30eb05f8,
    name: "login"
  }, {
    path: "/profile",
    component: _15ffea0a,
    name: "profile"
  }, {
    path: "/reset_password",
    component: _78a2a20c,
    name: "reset_password"
  }, {
    path: "/tasks",
    component: _b98e10c6,
    children: [{
      path: "",
      component: _01629460,
      name: "tasks"
    }, {
      path: ":id",
      component: _7b4a2ec8,
      name: "tasks-id"
    }]
  }, {
    path: "/cex/api",
    component: _2a10d4a0,
    name: "cex-api"
  }, {
    path: "/profile/add-wallets",
    component: _312e99d7,
    name: "profile-add-wallets"
  }, {
    path: "/profile/billing",
    component: _1dea3367,
    name: "profile-billing"
  }, {
    path: "/profile/onboard",
    component: _69f63860,
    name: "profile-onboard"
  }, {
    path: "/profile/recharge",
    component: _5f11878e,
    name: "profile-recharge"
  }, {
    path: "/profile/billing/paid",
    component: _4399aa72,
    name: "profile-billing-paid"
  }, {
    path: "/",
    component: _674362e1,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
