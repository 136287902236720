
export default {
  name: "DraggingDropping",

  props: {
    name: {
      type: String,
      default: "",
    },
    projectName: {
      type: String,
      default: "",
    },
    serviceName: {
      type: String,
      default: "",
    },
    dex_group: {
      type: [String, Number],
      default: "",
    },
    dex_order: {
      type: [Number, null],
      default: null,
    },
  },

  computed: {
    isBlocked() {
      return this.dex_group === "blocked" || this.dex_order;
    },
  },

  methods: {
    start(e) {
      if (this.isBlocked) return;

      e.dataTransfer.setData("name", this.name);
      e.dataTransfer.setData("oldOrder", this.$vnode.key);
      e.dataTransfer.setData("draggedProject", this.projectName);
      e.dataTransfer.setData("draggedService", this.serviceName);
    },

    over(e) {
      const dex = e.target.closest(".dex-drop");

      dex.classList.contains("blocked")
        ? dex.classList.add("disabled")
        : dex.classList.add("order");

      e.preventDefault();
    },

    leave(e) {
      const dex = e.target.closest(".dex-drop");

      dex.classList.remove("order");
      dex.classList.remove("disabled");
    },

    drop(e) {
      const dex = e.target.closest(".dex-drop");

      dex.classList.remove("order");

      if (dex.classList.contains("blocked")) {
        return dex.classList.remove("disabled");
      }

      const data = {
        oldOrder: +e.dataTransfer.getData("oldOrder"),
        newOrder: this.$vnode.key,
        draggedProjectName: e.dataTransfer.getData("draggedProject"),
        draggedServiceName: e.dataTransfer.getData("draggedService"),
        targetProjectName: this.projectName,
        targetServiceName: this.serviceName,
      };

      if (dex && this.name === e.dataTransfer.getData("name")) {
        this.$emit("on-order-changed", data);
      }
    },
  },
};
