
export default {
  name: "Activity",

  props: {
    isWallet: {
      type: Boolean,
      default: false,
    },
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
    dex: {
      type: String,
      default: "",
    },
    service: {
      type: [Object, Array],
      default: () => {},
    },
  },

  data: () => ({
    min: 1,
    max: 100,
    step: 1,
    showSettings: false,
    stargateFinance: false,
    stargateFinanceAmount: 0,
    slippage: 0,
    dstNativeAmount: 0,
    NFTfiled: "Not found",
    amount: 1,
  }),

  computed: {
    wallets() {
      return this.$store.state.wallets;
    },
    networksFrom() {
      return (
        this.service.networksTokens.network_from.map((item) => item.network) ??
        []
      );
    },
    tokensIn() {
      const network_from = (
        this.service.networksTokens.network_from.find(
          (item) => item.network === this.service.network_from
        ) ?? this.service.networksTokens.network_from[0]
      )?.token_in;

      return Object.keys(network_from) ?? [];
    },
    networksTo() {
      const token_in = (
        this.service.networksTokens.network_from.find(
          (item) => item.network === this.service.network_from
        ) ?? this.service.networksTokens.network_from[0]
      )?.token_in;
      const network_to = (
        token_in[this.service.token_in] ?? token_in[Object.keys(token_in)[0]]
      )?.network_to;

      return network_to.map((item) => item.network) ?? [];
    },
    tokensOut() {
      const token_in =
        (
          this.service.networksTokens.network_from.find(
            (item) => item.network === this.service.network_from
          ) ?? this.service.networksTokens.network_from[0]
        )?.token_in ?? [];
      const network_to = (
        token_in[this.service.token_in] ?? token_in[Object.keys(token_in)[0]]
      )?.network_to;

      return (
        (
          network_to?.find(
            (item) => item.network === this.service.network_to
          ) ?? network_to[0]
        )?.token_out ?? []
      );
    },
    get_project_icon() {
      return (
        this.service.project_icon[this.service.currentProject] ??
        this.service.project_icon[Object.keys(this.service.project_icon)[0]]
      );
    },
    get_project_name() {
      return (
        this.service?.project_name[this.service?.currentProject] ??
        this.service?.project_name[Object.keys(this.service?.project_name)[0]]
      );
    },
    filterNFT() {
      return Object.keys(this.service.nft).indexOf("filed") > -1
        ? []
        : Object.keys(this.service.nft);
    },
    listNFT() {
      if (!Object.keys(this.service.nft).length && this.service.nft_request) {
        return ["Not found"];
      }
      return (
        this.service.nft[this.service.network_from] ??
        this.service.nft[Object.keys(this.service.nft)[0]] ??
        []
      );
    },

    isNFT() {
      return this.listNFT.length;
    },

    get_gasReful() {
      return (
        this.service?.gasReful?.find(
          (item) => item.network === this.service.network_to
        )?.limit ?? 0.001
      );
    },

    setCheckboxClass() {
      return this.service.dex_group === "blocked"
        ? "lock"
        : this.service.dex_order
        ? "order"
        : "";
    },
  },

  methods: {
    setDexOrder(e) {
      this.$emit("on-check", this.service.key);
    },

    get_nft() {
      if ("get_nft" in this._events) {
        this.$emit("get_nft", { ...this.service });
      }
    },

    getNftName(name) {
      const index = this.filterNFT.indexOf(name);
      return index > -1 ? index : 0;
    },

    swapPlaces() {
      if ("swapPlaces" in this._events) {
        this.$emit("swapPlaces", { ...this.service });
      }
    },

    whichDex(dex) {
      return this.dex === dex;
    },

    setSwapAmountType(event) {
      this.$emit("on-set-swap-type", event.target.checked);
    },

    applySwapRange() {
      this.$emit("on-set-swap-range", {
        min: this.service.min_swap_range,
        max: this.service.max_swap_range,
      });
    },

    submitData(e) {
      const network_from = this.service.networksTokens.network_from;
      const { value, type, name, checked } = e.target;

      if (type === "number" && name === "slippage") {
        this.slippage = +value;
      }
      if (type === "number" && name === "dst_native_amount") {
        this.dstNativeAmount =
          +value < this.get_gasReful ? +value : this.get_gasReful;
      }

      switch (name) {
        case "network_from":
          var token_in =
            network_from.find((item) => item.network === value)?.token_in ??
            network_from[Object.keys(network_from)[0]]?.token_in;

          var set_token_in = token_in[this.service.token_in]
            ? this.service.token_in
            : Object.keys(token_in)[0];

          var network_to =
            token_in[set_token_in]?.network_to ??
            token_in[Object.keys(token_in)[0]]?.network_to;

          var set_network_to =
            network_to.find(
              (item) => item.network === this.service.network_to
            ) ?? network_to[Object.keys(network_to)[0]];

          if ("submitData" in this._events) {
            this.$emit("submitData", {
              name: "network_from",
              type: "select-one",
              value: value,
            });

            this.$emit("submitData", {
              name: "token_in",
              type: "select-one",
              value: set_token_in,
            });

            this.$emit("submitData", {
              name: "network_to",
              type: "select-one",
              value: set_network_to.network,
            });

            this.$emit("submitData", {
              name: "token_out",
              type: "select-one",
              value: set_network_to.token_out[0],
            });

            if (
              Object.keys(this.service.nft).length > 0 &&
              this.service.nft[value]
            ) {
              this.$emit("submitData", {
                name: "token_id",
                type: "select-one",
                value: this.service.nft[value][0],
              });
            }
          }
          break;
        case "token_in":
          var token_in =
            network_from.find(
              (item) => item.network === this.service.network_from
            )?.token_in ?? network_from[Object.keys(network_from)[0]]?.token_in;

          var network_to =
            token_in[value]?.network_to ??
            token_in[Object.keys(token_in)[0]]?.network_to;
          var set_network_to =
            network_to.find(
              (item) => item.network === this.service.network_to
            ) ?? network_to[Object.keys(network_to)[0]];

          if ("submitData" in this._events) {
            this.$emit("submitData", {
              name: "token_in",
              type: "select-one",
              value: value,
            });
            this.$emit("submitData", {
              name: "network_to",
              type: "select-one",
              value: set_network_to.network,
            });
            this.$emit("submitData", {
              name: "token_out",
              type: "select-one",
              value: set_network_to.token_out[0],
            });
          }

          break;
        case "network_to":
          var token_in =
            network_from.find(
              (item) => item.network === this.service.network_from
            )?.token_in ?? network_from[Object.keys(network_from)[0]]?.token_in;
          var network_to =
            token_in[this.service.token_in]?.network_to ??
            token_in[Object.keys(token_in)[0]]?.network_to;
          var set_network_to =
            network_to.find((item) => item.network === value) ??
            network_to[Object.keys(network_to)[0]];

          if ("submitData" in this._events) {
            this.$emit("submitData", {
              name: "network_to",
              type: "select-one",
              value: value,
            });
            this.$emit("submitData", {
              name: "token_out",
              type: "select-one",
              value: set_network_to.token_out[0],
            });
            if (this.isWallet && this.service.type === "bridge_refuel") {
              this.$emit("submitData", {
                name: "amount",
                type: "number",
                value: 0.001,
              });
            }
          }
          break;
        case "dst_native":
          if ("submitData" in this._events) {
            this.$emit("submitData", {
              name: "dst_native",
              type: "checkbox",
              value: checked,
            });
            if (checked) {
              this.$emit("submitData", {
                name: "dst_native_amount",
                type: "number",
                value: this.stargateFinanceAmount,
              });
            } else {
              this.$emit("submitData", {
                name: "dst_native_amount",
                type: "number",
                value: "",
              });
            }
          }
          break;

        default:
          if ("submitData" in this._events) {
            if (this.service.type === "bridge_refuel") {
              this.amount =
                +value >= this.get_gasReful ? this.get_gasReful : +value;
            }

            this.$emit("submitData", {
              name: type === "button" ? "params_type" : name,
              type: type === "button" ? "eralend" : type,
              value:
                type === "button"
                  ? this.service.params_type === "supply"
                    ? "withdraw"
                    : "supply"
                  : type === "checkbox"
                  ? checked
                  : this.service.type === "bridge_refuel"
                  ? this.amount
                  : name === "dst_native_amount"
                  ? this.dstNativeAmount
                  : value,
            });
          }
          break;
      }
    },

    deleteItem(e) {
      if ("deleteItem" in this._events) {
        this.$emit("deleteItem", e);
      }
    },
    handleClickOutside(e) {
      const modalContainer = this.$refs.gear;
      if (modalContainer && !modalContainer.contains(e.target.parentNode)) {
        this.showSettings = false;
      }
    },
    setAmount(value) {
      if ("submitData" in this._events) {
        this.$emit("submitData", {
          name: "amount",
          type: "range",
          value: value,
        });
      }
    },
    upDownAmount(direction) {
      if (direction) {
        if (
          this.service.req_name === "dmail" &&
          this.service.type === "dmail"
        ) {
          if (this.service.amount + 1 < 6) {
            this.setAmount((this.service.amount += 1));
          }
        } else {
          if (this.service.amount + 1 < 101) {
            this.setAmount((this.service.amount += 1));
          }
        }
      } else {
        if (
          this.service.req_name === "dmail" &&
          this.service.type === "dmail"
        ) {
          if (this.service.amount - 1 < 6) {
            this.setAmount((this.service.amount -= 1));
          }
        } else {
          if (this.service.amount - 1 < 101) {
            this.setAmount((this.service.amount -= 1));
          }
        }
      }
    },
    upDownGas(direction) {
      const persent = this.get_gasReful / 100;
      if ("submitData" in this._events) {
        if (direction) {
          /// +
          if (
            this.service.amount + persent <= this.get_gasReful &&
            this.service.amount + persent > 0
          ) {
            if ("submitData" in this._events) {
              this.$emit("submitData", {
                name: "amount",
                type: "number",
                value: Number("" + (this.service.amount + persent).toFixed(4)),
              });
            }
          }
        } else {
          /// -
          if (
            this.service.amount - persent <= this.get_gasReful &&
            this.service.amount - persent > 0
          ) {
            if ("submitData" in this._events) {
              this.$emit("submitData", {
                name: "amount",
                type: "number",
                value: Number("" + (this.service.amount - persent).toFixed(4)),
              });
            }
          }
        }
      }
    },

    setCurrentValues() {
      const { networksTokens, currentProject, network_to, type } = this.service;

      const network_from =
        networksTokens?.network_from.find(
          (item) =>
            item.network.replace(" ", "").toLowerCase() === currentProject
        ) ?? networksTokens?.network_from[0];

      const token_in = Object.keys(network_from?.token_in)[0];

      const networkTo =
        network_from.token_in[token_in].network_to.find(
          (item) => item.network === network_to
        ) ??
        network_from.token_in[token_in].network_to[
          Object.keys(network_from.token_in[token_in].network_to)[0]
        ];

      const token_out = networkTo.token_out.includes(network_to)
        ? networkTo.token_out
        : networkTo.token_out[0];

      if (type === "bridge_refuel") this.amount = 0.001;

      if ("submitData" in this._events) {
        if (this.service.network_from === "") {
          this.$emit("submitData", {
            name: "network_from",
            type: "select-one",
            value:
              this.service.type === "bridge_token"
                ? networkTo.network
                : network_from.network,
          });
        }

        if (this.service.token_in === "") {
          this.$emit("submitData", {
            name: "token_in",
            type: "select-one",
            value: token_in, /// С учетом выбронной сети
          });
        }
        if (network_to === "") {
          this.$emit("submitData", {
            name: "network_to",
            type: "select-one",
            value:
              this.service.type === "bridge_token"
                ? network_from.network
                : networkTo.network,
          });
        }
        if (this.service.token_out === "") {
          this.$emit("submitData", {
            name: "token_out",
            type: "select-one",
            value: token_out, /// С учетом выбронной сети
          });
        }
        if (this.service.params_type === "") {
          this.$emit("submitData", {
            name: "params_type",
            type: "eralend",
            value: "supply", /// С учетом выбронной сети
          });
        }
      }
    },
  },

  async updated() {
    if (this.listNFT.length > 0 && !this.service?.nft_request) {
      const network_from = this.service.networksTokens?.network_from.find(
        (item) => item.network === this.filterNFT[0]
      );

      const token_in = Object.keys(network_from?.token_in)[0];
      const network_to =
        network_from.token_in[token_in].network_to.find(
          (item) => item.network === this.service.network_to
        ) ??
        network_from.token_in[token_in].network_to[
          Object.keys(network_from.token_in[token_in].network_to)[0]
        ];
      const token_out = network_to.token_out.includes(this.service.network_to)
        ? network_to.token_out
        : network_to.token_out[0];

      if ("submitData" in this._events) {
        this.$emit("submitData", {
          name: "network_from",
          type: "select-one",
          value: network_from.network, /// С учетом выбронной сети
        });
        this.$emit("submitData", {
          name: "token_in",
          type: "select-one",
          value: token_in, /// С учетом выбронной сети
        });
        this.$emit("submitData", {
          name: "network_to",
          type: "select-one",
          value: network_to.network, /// С учетом выбронной сети
        });
        this.$emit("submitData", {
          name: "token_out",
          type: "select-one",
          value: token_out, /// С учетом выбронной сети
        });
        this.$emit("submitData", {
          name: "token_id",
          type: "select-one",
          value: this.service.nft[network_from.network][0], /// С учетом выбронной сети
        });
      }
    }

    if (
      this.service.type === "bridge_nft" ||
      this.service.type === "bridge_tokens"
    ) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      this.stargateFinanceAmount = this.$store.getters.find_stargateFinance(
        this.service.network_to
      );
    }
  },

  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    if (typeof this.service !== "undefined") {
      if (this.service.status === null && !this.service["address"]) {
        this.setCurrentValues();
      }

      if ("submitData" in this._events && this.service.amount <= 0) {
        this.$emit("submitData", {
          name: "amount",
          type: "number",
          value:
            this.service.type === "bridge_refuel"
              ? 0.001
              : this.service.type === "dmail"
              ? 1
              : 50,
        });
      }
    }
  },

  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
