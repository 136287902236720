
export default {
  name: "Message",
  components: {},
  props: {
    login: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    messages: [],
    showMSG: false,
    clearMessages: false,
    timeHidden: 4000,
  }),
  computed: {
    messageServer() {
      return this.$store.state.message;
    },
    isAuthenticated() {
      return this.$store.getters?.isAuthenticated;
    },
  },
  methods: {
    closeMessage() {
      clearTimeout(this.showMSG);
      this.showMSG = false;
      clearTimeout(this.clearMessages);
      this.clearMessages = false;

      if ("notyLogin" in this.$refs) {
        this.$refs.notyLogin.style.transform = `translate(0, -${
          this.messages.length * 51
        }px)`;
      } else {
        this.$refs.notySite.style.transform = `translate(0, -${
          this.messages.length * 51
        }px)`;
      }

      this.clearMessages = setTimeout(() => {
        if (this.messages.filter((item) => item).length > 0) {
          this.messages = [];
        } else {
          this.messages.shift();
        }

        if ("notyLogin" in this.$refs) {
          this.$refs.notyLogin.removeAttribute("style");
        } else {
          this.$refs.notySite.removeAttribute("style");
        }
      }, 500); // time road messanger before delete messanger
    },
  },
  watch: {
    messages(newVal, oldVal) {
      if (this.messages.filter((item) => item).length > 0) {
        clearTimeout(this.showMSG);
        this.showMSG = false;

        if (newVal[0] === undefined) {
          if ("notyLogin" in this.$refs) {
            this.$refs.notyLogin.style.transform = `translate(0, -${
              (this.messages.length - 2) * 51 //25
            }px)`;
          } else {
            this.$refs.notySite.style.transform = `translate(0, -${
              (this.messages.length - 2) * 51
            }px)`;
          }
        } else {
          if ("notyLogin" in this.$refs) {
            this.$refs.notyLogin.style.transform = `translate(0, -${
              (this.messages.length - 1) * 51 //25
            }px)`;
          } else {
            this.$refs.notySite.style.transform = `translate(0, -${
              (this.messages.length - 1) * 51
            }px)`;
          }
        }

        this.showMSG = setTimeout(() => {
          clearTimeout(this.showMSG);
          this.showMSG = false;
          if ("notyLogin" in this.$refs) {
            this.$refs.notyLogin.style.transform = `translate(0, -${
              this.messages.length * 51
            }px)`;
          } else {
            this.$refs.notySite.style.transform = `translate(0, -${
              this.messages.length * 51
            }px)`;
          }

          clearTimeout(this.clearMessages);
          this.clearMessages = false;
          this.clearMessages = setTimeout(() => {
            if (this.messages.filter((item) => item).length > 0) {
              this.messages = [];
            } else {
              this.messages.shift();
            }
            if ("notyLogin" in this.$refs) {
              this.$refs.notyLogin.removeAttribute("style");
            } else {
              this.$refs.notySite.removeAttribute("style");
            }
          }, 500); // time road messanger before delete messanger
        }, this.timeHidden); //time show messanget
      }
    },
    messageServer(newVall, oldVall) {
      this.messages.push(newVall);
    },
  },
  updated() {},
  mounted() {
    // setTimeout(() => {
    //   this.messages.push("test messanger 1");
    // }, 2000);
    // setTimeout(() => {
    //   this.messages.push("test messanger2");
    // }, 6000);
    // setTimeout(() => {
    //   this.messages.push("test long long messanger 3");
    // }, 12000);
    // setTimeout(() => {
    //   this.messages.push("test long long messanger 3");
    // }, 14000);
    // setTimeout(() => {
    //   this.messages.push(
    //     "test wery long text messanger 4, test wery long text messanger 4, test wery long text messanger 4"
    //   );
    // }, 1800);
  },
  created() {},
  destroyed() {
    //
  },
};
