
export default {
  name: "loginPage",

  data: () => ({
    forgotEmail: "",
    isLoading: false,
    isActiveForm: 0,
    loginUser: {},
    registerUser: {},
    validPass: {
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      six: null,
    },
    confirmPassword: false,
    colorLogo: "",
    checkUser: "",
  }),

  computed: {
    isUser() {
      return this.$store.state.auth.user;
    },
    checkPassword() {
      return this.$store.getters.checkPassword;
    },
  },

  methods: {
    async register() {
      if (
        this.confirmPassword &&
        Object.values(this.validPass).filter(
          (item) => typeof item !== Boolean && !item
        ).length === 0
      ) {
        this.registerUser.colorLogo = this.colorLogo;
        this.isLoading = true;
        const resp = await this.$axios
          .post("/api/register", { ...this.registerUser })
          .catch((err) => err?.response?.data);

        this.isLoading = false;
        if ("errors" in resp) {
          this.$store.dispatch("setMessage", resp.errors?.email?.msg);
          return;
        }

        this.$store.dispatch("setMessage", resp.data.message);
      } else {
        this.$store.dispatch("setMessage", {
          status: "wrong",
          message: "Your password is low or haven't confirm",
        });
      }
    },
    async login() {
      if (Object.values(this.loginUser).length > 1) {
        const resp = await this.$auth
          .loginWith("local", { data: this.loginUser })
          .then(({ data }) => {
            const token = data.accessToken;
            const expiresIn = data.expiresIn;
            this.$store.dispatch("setToken", { token, expiresIn });
            return data.message;
          })
          .catch((err) => JSON.parse(err.request.response).message);
        this.$store.dispatch("setMessage", resp);
      } else {
        this.$store.dispatch("setMessage", {
          status: "wrong",
          message: "Invalid e-mail or password entered",
        });
      }
    },
    requirements(e) {
      if (e.type === "focusin") {
        this.$refs.requirements.classList.add("show");
      } else if (e.type === "focusout") {
        this.$refs.requirements.classList.remove("show");
      }
    },
    async inputValue(e) {
      if (e.target.closest("form").name === "login") {
        if (this.validateEmail(e.target.value) && e.target.type === "email") {
          this.loginUser[e.target.type] = e.target.value;
        } else if (
          (/[-+=(){}\[\]@$%^*?!~:;,./\\]/.test(e.target.value) ||
            /[а-яА-ЯёЁ]+/g.test(e.target.value)) &&
          e.target.type === "email"
        ) {
          this.loginUser[e.target.type] = e.target.value;
          // } else if (e.target.type === "password" && (await this.check_password(e.target.value))) {
          //   this.loginUser[e.target.type] = e.target.value;
        } else if (e.target.type === "password") {
          this.loginUser[e.target.type] = e.target.value;
        }
      } else if (e.target.closest("form").name === "register") {
        if (e.target.type === "email" && this.validateEmail(e.target.value)) {
          this.registerUser[e.target.type] = e.target.value;
        } else if (
          (/[-+=(){}\[\]@$%^*?!~:;,./\\]/.test(e.target.value) ||
            /[а-яА-ЯёЁ]+/g.test(e.target.value)) &&
          e.target.type === "email"
        ) {
          this.registerUser[e.target.type] = e.target.value;
        } else if (e.target.type === "text" && e.target.value.length > 3) {
          this.registerUser.username = e.target.value;
        } else if (e.target.type === "text") {
          this.registerUser.username = e.target.value;
        } else if (e.target.name === "password") {
          this.validPass = this.checkPassword(e.target.value);
          this.registerUser[e.target.name] = e.target.value;
        } else if (
          e.target.name === "confirm" &&
          this.registerUser.password === e.target.value
        ) {
          this.registerUser[e.target.name] = e.target.value;
          this.confirmPassword = true;
        } else if (e.target.name === "confirm") {
          this.registerUser[e.target.name] = e.target.value;
          this.confirmPassword = false;
        }
      }
    },
    submitForm(e) {
      if (e.target.closest("form").name === "login") {
        this.login();
      } else {
        const baseColor = [
          "#00ff8fb3, #00a1ffb3",
          "#e100ffb3, #7f00ffb3",
          "#ff7db8b3, #ee2a7bb3",
          "#fbb040b3, #ef4136b3",
          "#00baffb3, #2033c1b3",
          "#FED0C7, #FE8DC6",
        ];
        this.colorLogo =
          baseColor[Math.floor(Math.random() * baseColor.length)];
        this.register();
      }
    },
    async forgotPassword() {
      if (this.forgotEmail.length > 0) {
        const resp = await this.$axios
          .$post("/api/forgot_password", { email: this.forgotEmail })
          .then((res) => res.message)
          .catch((err) => err.response.data.message);
        this.$store.dispatch("setMessage", resp);
      } else {
        this.$store.dispatch("setMessage", "Please, enter your e-mail");
      }
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    async check_username(username) {
      return await this.$axios
        .$post(
          "/api/check_username",
          { email: this.registerUser.email, username },
          {
            progress: false, // Disable integration with the progress bar specifically for this query
          }
        )
        .catch((err) => false);
    },
    async checkUsername(e) {
      if (await this.check_username(e.target.value)) {
        this.checkUser = "correct";
      } else {
        this.checkUser = "wrong";
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.$nuxt.$loading.start();
      setTimeout(() => this.$nuxt.$loading.finish(), 500);
    });
  },
};
