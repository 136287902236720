export default ({ app, store }, inject) => {
  inject("requestFormat", {
    formatPath(service) {
      return {
        activity: service.type,
        service: service.req_name,
        params: {
          amount: service.amount > 100 ? 100 : service.amount,
          is_exact_amount: service.is_exact_amount,
          type: !service.params_type ? "supply" : service.params_type,

          networkFrom: service.network_from,
          networkTo: service.network_to,
          tokenIn: service.token_in,
          tokenOut: service.token_out,

          slippage:
            Number(service.slippage) > 10
              ? 10
              : Number(service.slippage) > 0
              ? Number(service.slippage)
              : 1,
          dstNativeAmount: Number(service.dst_native_amount ?? "0"), //Gaz destiny
          token_id: service.token_id ?? "", // "MEME #230475"
          // bridge_to: service.bridge_to,
        },
      };
    },
  });
};
