import { render, staticRenderFns } from "./WalletServices.vue?vue&type=template&id=40c2d52e&scoped=true&lang=pug"
import script from "./WalletServices.vue?vue&type=script&lang=js"
export * from "./WalletServices.vue?vue&type=script&lang=js"
import style0 from "./WalletServices.vue?vue&type=style&index=0&id=40c2d52e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40c2d52e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/var/www/app.routs/components/Button.vue').default,Activity: require('/var/www/app.routs/components/Activity.vue').default,DraggingDropping: require('/var/www/app.routs/components/DraggingDropping.vue').default})
