
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "CalendarPage",

  data() {
    return {
      monthsOfYear: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      daysOfWeek: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      currentDay: new Date(),
      startDate: new Date(),
      dayAmount: 0,
      isDayView: false,
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    // Store
    ...mapGetters("tasks", ["tasks"]),

    isMobile() {
      return this.windowWidth < 1024;
    },

    // Header
    previousButton() {
      return this.isDayView ? "Previous day" : "Previous week";
    },

    nextButton() {
      return this.isDayView ? "Next day" : "Next week";
    },

    range() {
      let range;

      if (this.isDayView) {
        const monthIndex = this.currentDay.getMonth();
        const month = this.monthsOfYear[monthIndex];
        const dayNumber = this.currentDay.getDate();

        range = `${month} ${dayNumber}`;
      } else {
        const firstDay = this.currentRange[0];
        const lastDay = this.currentRange[this.currentRange.length - 1];

        range = `${firstDay.month} ${firstDay.number} - ${lastDay.month} ${lastDay.number}`;
      }

      return range;
    },

    // Table
    currentRange() {
      let startDate = new Date(this.startDate);
      const daysToMonday = (startDate.getDay() + 6) % 7;

      this.startDate.setDate(
        startDate.getDate() - daysToMonday + this.dayAmount
      );

      const weekDates = [];

      for (let i = 0; i < 7; i++) {
        const currentDate = new Date(this.startDate);
        currentDate.setDate(this.startDate.getDate() + i);

        const year = currentDate.getFullYear();

        const month = this.monthsOfYear[currentDate.getMonth()];
        const monthNumber = String(currentDate.getMonth() + 1).padStart(2, "0");

        const day = this.daysOfWeek[currentDate.getDay()];
        const dayNumber = currentDate.getDate();
        const dayNumberWithZero = String(currentDate.getDate()).padStart(
          2,
          "0"
        );

        const date = `${year}-${monthNumber}-${dayNumberWithZero}`;

        weekDates.push({
          month,
          day,
          number: dayNumber,
          date,
        });
      }

      this.dayAmount = 0;

      return weekDates;
    },
  },

  methods: {
    ...mapActions("tasks", ["getTasks", "deleteTask", "resetTaskDate"]),

    formatDate(date) {
      const newDate = new Date(date);

      const year = newDate.getFullYear();
      const month = (newDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
      const day = newDate.getDate().toString().padStart(2, "0");

      const formattednewDate = `${year}-${month}-${day}`;

      return formattednewDate;
    },

    getTasksForDay(date) {
      return this.tasks?.length
        ? this.tasks.filter((el) => {
            const task = new Date(el.completionDate);
            const day = new Date(date);

            return (
              task.getFullYear() === day.getFullYear() &&
              task.getMonth() === day.getMonth() &&
              task.getDate() === day.getDate()
            );
          })
        : [];
    },

    goPrevRange() {
      if (this.isDayView) {
        const newDate = new Date(this.currentDay);
        newDate.setDate(newDate.getDate() - 1);

        this.currentDay = newDate;
      } else {
        this.dayAmount = -7;
      }
    },

    goNextRange() {
      if (this.isDayView) {
        const newDate = new Date(this.currentDay);
        newDate.setDate(newDate.getDate() + 1);

        this.currentDay = newDate;
      } else {
        this.dayAmount = 7;
      }
    },

    setTime(date, isFullDate = false) {
      const createdHours = String(new Date(date).getHours()).padStart(2, "0");
      const createdMinutes = String(new Date(date).getMinutes()).padStart(
        2,
        "0"
      );

      if (isFullDate) {
        const createdMonth = String(new Date(date).getMonth() + 1).padStart(
          2,
          "0"
        );
        const createdDay = String(new Date(date).getDay()).padStart(2, "0");

        return `${createdDay}.${createdMonth} ${createdHours}:${createdMinutes}`;
      }

      return `${createdHours}:${createdMinutes}`;
    },

    checkStatus(status) {
      switch (status) {
        case "waiting":
          return "blue";
        case "signed":
        case "pause":
        case "progress":
          return "purple";
        case "canceled":
          return "orange";
        case "finished":
          return "green";
        default:
          return "";
      }
    },

    isToday(date) {
      return date === this.formatDate(new Date());
    },

    isTodayOrAfter(date) {
      return this.formatDate(date) > this.formatDate(new Date());
    },

    createTask(date) {
      const hours = new Date().getHours();
      const minutes = new Date().getMinutes();
      const seconds = new Date().getSeconds();

      const newDate = new Date(
        new Date(date).setHours(hours, minutes, seconds, 0)
      );

      this.resetTaskDate(newDate);
      this.$router.push("/tasks/new");
    },

    editTask(event, id, completionDate) {
      if (!event.target.closest(".calendar__icon")) {
        if (completionDate) this.resetTaskDate(completionDate);

        this.$router.push(`/tasks/${id}`);
      }
    },

    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
      this.isDayView = window.innerWidth < 1024;
    },
  },

  created() {
    this.getTasks();
    this.isDayView = window.innerWidth < 1024;
    window.addEventListener("resize", this.updateWindowWidth); // Add resize event listener
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth); // Remove resize event listener
  },
});
