
export default {
  name: "Select",
  props: {
    options: {
      type: Array,
      default: "",
    },
    subData: {
      type: [String, Array, Object],
      default: () => "",
    },
    type: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    selected: {
      type: Number,
      default: 0,
    },
    classList: {
      type: [String, Array],
      default: "",
    },
    hidden: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    hashGenerator() {
      return this.$store.getters.hashGenerator;
    },
    nameInput() {
      return this.name.length
        ? this.name
        : this.$vnode.key
        ? this.$vnode.key
        : this.hashGenerator(10);
    },
    hash() {
      return this.hashGenerator(6);
    },
  },

  methods: {
    submitValue(e) {
      if (e.type in this._events) {
        e.subData = this.subData;
        this.$emit(e.type, e);
      }
    },
  },
};
