
export default {
  name: "Input",
  model: {
    event: "update:modelValue",
  },

  props: {
    subData: {
      type: [Number, String, Array, Object],
      default: () => "",
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    classList: {
      type: [String, Array],
      default: "",
    },
    placeholder: {
      type: [Number, String],
      default: "",
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: [Number, String, File],
      default: "",
    },
    checked: {
      type: String,
      default: "",
    },
    min: {
      type: [String, Number],
      default: "",
    },
    maxlength: {
      type: [String, Number],
      default: "",
    },
    pattern: {
      type: [String, Number],
      default: "",
    },
    max: {
      type: [String, Number],
      default: "",
    },
    form: {
      type: String,
      default: "",
    },
    step: {
      type: [Number, String],
      default: "",
    },
    focus: Boolean,
    default: false,
  },

  computed: {
    hashGenerator() {
      return this.$store.getters.hashGenerator;
    },

    nameInput() {
      return this.name.length
        ? this.name
        : this.$vnode.key
        ? this.$vnode.key
        : this.hashGenerator(10);
    },

    hash() {
      return this.hashGenerator(6);
    },
  },

  methods: {
    submitValue(e) {
      if (e.type in this._events) {
        e.subData = this.subData;

        // Минимум 1 для gaz
        if (this.name === "gasPrice" && e.target.value < 1) {
          e.target.value = "";
        }

        // Ограничение до 10 для slippage
        if (this.name === "slippage" && e.target.value > 10) {
          e.target.value = 10;
        }

        // Только целые числа от 1 до 100 для процентов
        if (this.name === "amount") {
          e.target.value =
            e.target.value < 1
              ? ""
              : e.target.value > 100
              ? 100
              : Math.floor(e.target.value);
        }

        this.$emit(e.type, e);
      }
    },
  },

  mounted() {
    if (this.focus) this.$refs.input.focus();
  },
};
