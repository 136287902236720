
export default {
  name: "Checkbox",

  props: {
    subData: {
      type: [String, Array, Object],
      default: () => "",
    },
    value: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    classList: {
      type: String,
      default: "",
    },
    checked: {
      type: [String, Boolean],
      default: false,
    },
    disabled: {
      type: [String, Boolean],
      default: false,
    },
    img: {
      type: String,
      default: "",
    },
    order: {
      type: [Number, null],
      default: null,
    },
  },

  computed: {
    hashGenerator() {
      return this.$store.getters.hashGenerator;
    },
    keyInputComponent() {
      return this.$vnode.key ?? this.hashGenerator(6);
    },
    nameInput() {
      return this.name.length
        ? this.name
        : this.$vnode.key
        ? this.$vnode.key
        : this.hashGenerator(10);
    },
  },

  methods: {
    submitValue(e) {
      if (this._events?.click) {
        e.subData = this.subData;
        this.$emit("click", e);
      }
    },
  },
};
