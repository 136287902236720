import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=4ee28077&scoped=true&lang=pug"
import script from "./Pagination.vue?vue&type=script&lang=js"
export * from "./Pagination.vue?vue&type=script&lang=js"
import style0 from "./Pagination.vue?vue&type=style&index=0&id=4ee28077&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ee28077",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Select: require('/var/www/app.routs/components/Select.vue').default})
