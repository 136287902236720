export const state = () => ({
  breadCrumbs: {
    login: "Login",

    wallets: "All Wallets",
    "wallets-ethereum": ["All Wallets", "Ethereum"],
    "wallets-layerzero": ["All Wallets", "LayerZero"],
    "wallets-add-new": ["All Wallets", "Add New"],

    tasks: "My Tasks",
    "tasks-id": ["My Tasks", "Add New"],

    cex: "Withdraw OKX",
    "cex-api": ["Withdraw OKX", "API Keys"],

    profile: "My Profile",
    "profile-add-wallets": ["My Profile", "Add Wallets"],
    "profile-billing": ["My Profile", "Billing"],
    "profile-recharge": ["My Profile", "Recharge"],
    "profile-onboard": ["My Profile", "Welcome on board"],
  },

  withdraw: {
    done: ["Successfully withdrew {{amount}} {{token}} on {{date}}"],
    error: [
      "Unfortunately, withdrawing for this Token is not allowed at the moment",
      "Unfortunately, this amount is not within limits",
      "Unfortunately, your IP {param0} is not included in your API Key's IP Whitelist",
      "Unfortunately, you have insufficient Balance",
      "Unfortunately, this withdrawal amount exceeds your Daily Withdrawal Limit",
      "Unfortunately, this Withdrawal Address is not currently Whitelisted for Verification Exemption",
      "Unfortunately, Withdrawal was suspended due to your account activity triggering a risk control. Please contact OKX Customer Support for assistance",
      "Unfortunately, withdrawals are temporarily suspended for your account due to a risk detected in your Withdrawal Address. Contact OKX Customer Support for assistance",
      "Unfortunately, you cannot withdraw Crypto within 24 hours of changing your Mobile Number, Email Address, or Google Authenticator",
      "Unfortunately, according to the OKX Compliance Policy. Please complete your Identity Verification (Level 1) to make a withdrawal",
      "Unfortunately, you have reached the Personal Information Verification (L1) Withdrawal Limit. Please complete Photo Verification (L2) to increase your Withdrawal Limit",
      "Unfortunately, for compliance, we are unable to provide services to Unverified Users. Please verify your identity to make a withdrawal",
      "Unfortunately, for compliance, we are unable to provide services to Users with Basic Verification (Level 1). Please complete Advanced Verification (Level 2) to make a withdrawal",
      "Unfortunately, this API key does not have permission to use this function.",
    ],
  },

  message: "",
  switchTheme: "",
  GAZ: 0,
  stargateFinance: [],
  exchangeUser: {},
  logExchange: [],
  exchange: {},
  wallets: [],
  services: [],
  networks: [],

  awaitIdTask: false,
  pathTransactions: {},
});

export const mutations = {
  set_token(state, value) {
    const token = value?.accessToken ?? value;
    state.auth.token = token ? token : null;
    state.auth.loggedIn = token ? true : false;
  },

  remove_token(state) {
    state.auth.token = null;
    state.auth.loggedIn = false;
  },

  setAddressesVisibility(state, value) {
    state.auth.user.isWalletsHidden = value;
  },

  switchingTheme(state, value) {
    state.switchTheme = value ? "light" : "night";
  },

  checked_wallet(state, value) {
    state.wallets.map((item) =>
      item.address === value.address ? (item.checked = value.checked) : item
    );
  },

  sorting_wallets(state) {
    state.wallets.sort(
      (a, b) => b.connected - a.connected || a > b || -(a < b)
    );
  },

  set_wallets(state, value) {
    state.wallets = value?.map(
      (item) => (
        ((item.checked = false),
        (item.nft = {}),
        (item.inProgress = item.connected),
        (item.output = item.output_last_activity
          ? [item.output_last_activity]
          : ["Waiting for the Withdrawal settings"])),
        { ...item }
      )
    );
  },

  set_wallets_output(state, value) {
    state.wallets.map((item) =>
      item.address === value.address ? (item.output = value.output) : null
    );
  },

  set_exchange(state, value) {
    const arr = {};
    for (let index = 0; index < Object.values(value).length; index++) {
      const exchange = value[index];
      arr[exchange.source] =
        typeof arr[exchange.source] === "undefined" ? [] : arr[exchange.source];

      exchange.networks = Object.values(exchange.networks);
      arr[exchange.source].push(exchange);
    }
    state.exchange = { ...arr };
  },

  connectWallets(state, value) {
    state.wallets.forEach((wallet, index) => {
      if (wallet.address === value) {
        state.wallets[index].connected = true;
      }
    });
  },

  disconnectWallets(state, value) {
    state.wallets.forEach((wallet, index) => {
      if (wallet.address === value) {
        state.wallets[index].connected = false;
      }
    });
  },

  set_message(state, value) {
    if (typeof value !== "undefined") {
      state.message = state.message === value ? value + " " : value;
    }
  },

  set_username(state, value) {
    state.auth.user.username = value;
  },

  set_avatar(state, value) {
    state.auth.user.path = value;
  },

  set_exchange_user(state, value) {
    const arr = {};
    for (let index = 0; index < value.length; index++) {
      const exch = {};
      const exchange = value[index];
      exchange?.key ? (exch.key = exchange.key) : null,
        exchange?.password ? (exch.password = exchange.password) : null,
        exchange?.secret ? (exch.secret = exchange.secret) : null;
      arr[exchange.exchange] = exch;
    }
    state.exchangeUser = arr;
  },

  set_log_exchange(state, value) {
    state.logExchange = value;
  },

  set_GAZ(state, value) {
    state.GAZ = value;
  },

  set_networks(state, value) {
    state.networks = value;
  },

  set_breadCrumbs(state, value) {
    if (!!typeof state.breadCrumbs[value.key] !== "string") {
      state.breadCrumbs[value.key] = [
        "My Tasks",
        value?.title?.length ? value?.title : "Add New",
        ["", "Services", "Settings", value?.end ? value?.end : "Logs"][
          value.val - 1
        ],
      ].filter((item) => item !== null && item !== undefined && item !== "");
    }
  },

  set_services(state, value) {
    state.services = [...value.map((item) => ((item.status = null), item))];
  },

  change_services(state, value) {
    state.services.map((item) =>
      item.id == value.id ? (item[value.key] = value.val) : null
    );
  },

  set_stargateFinance(state, value) {
    state.stargateFinance = value;
  },

  setAwaitIdTask(state, value) {
    state.awaitIdTask = value;
  },

  set_pathTransactions(state, value) {
    if (value.length > 0) {
      for (const address of value.map((item) => item.address)) {
        const data = value.find((item) => item.address === address);
        state.pathTransactions[address] = {
          path: data.path,
          transactions: data.transactions,
        };
      }
    } else {
      state.pathTransactions = {};
    }
  },
};

export const actions = {
  async setToken({ commit, dispatch }, { token }) {
    await this.$axios.setToken(token, "Bearer");
    commit("set_token", token);
    dispatch("getWallets");
    dispatch("metaMask");
    dispatch("getExchange");
    dispatch("get_stargateFinance");
    dispatch("getNetworks");
    dispatch("getExchangeUser");
    dispatch("plans/getUserSubscription", null, { root: true });
  },

  setMessage({ commit }, data) {
    commit("set_message", data);
  },

  async refreshToken({ commit, state }) {
    const token = await this.$axios.$post("/api/refresh", {
      token: state.auth.token,
    });
    const access = token.accessToken;
    const accessToken = "Bearer " + token.accessToken;
    this.$auth.setToken("local", accessToken);
    await this.$axios.setToken(access, "Bearer");
    await commit("set_token", access);
  },

  logout({ commit }) {
    commit("remove_token");
  },

  async switchWalletAddresses({ commit }, payload) {
    try {
      const resp = await this.$axios.$post(
        `${this.$config.API_SERVER_ETH}/api/users/wallets/hidden`,
        payload
      );

      commit("setAddressesVisibility", payload.isWalletsHidden);
      return resp;
    } catch (error) {
      console.error("Error switching wallet addresses visibility:", error);
      throw error;
    }
  },

  switchTheme({ commit }, data) {
    commit("switchingTheme", data);
  },

  async addWallets({ commit, dispatch }, payload) {
    let data = null;
    let error = null;

    try {
      data = await this.$axios.$post(
        `${this.$config.API_SERVER_ETH}/api/wallets`,
        payload
      );

      if (data?.message) commit("set_message", data.message);

      if (data) commit("set_wallets", data);

      await dispatch("metaMask");
    } catch (err) {
      error = err;
    }

    return { data, error };
  },

  setWalletsOutput({ commit }, data) {
    commit("set_wallets_output", data);
  },

  async getWallets({ commit, dispatch }) {
    const resp = await this.$axios
      .$get(`${this.$config.API_SERVER_ETH}/api/wallets`)
      .catch((err) =>
        err?.response?.data ? err?.response?.data : err.message
      );

    commit("set_message", resp.message);
    if (resp.length) await commit("set_wallets", resp);
    await dispatch("metaMask");
  },

  checkedWallet({ commit }, data) {
    commit("checked_wallet", data);
  },

  async getExchange({ commit }, data = {}) {
    const resp = await this.$axios
      .$get(`${this.$config.API_SERVER_ETH}/api/cex/tokens`, data)
      .catch((err) =>
        err?.response?.data ? err?.response?.data : err.message
      );

    if ("message" in resp) {
      commit("set_message", resp.message);
    } else {
      commit("set_exchange", resp);
    }
  },

  async changeWallets({ commit, dispatch }, data) {
    const resp = await this.$axios
      .$put(`${this.$config.API_SERVER_ETH}/api/wallets`, data)
      .catch((err) =>
        err?.response?.data ? err?.response?.data : err.message
      );
    commit("set_message", resp.message);
    commit("set_wallets", resp);
    await dispatch("metaMask");
  },

  sortingWallets({ commit }) {
    commit("sorting_wallets");
  },

  async removeWallet({ commit, dispatch }, data) {
    const request_data = {
      addresses: data.key,
    };
    const resp = await this.$axios
      .$delete(`${this.$config.API_SERVER_ETH}/api/wallets`, {
        data: request_data,
      })
      .catch((err) =>
        err?.response?.data ? err?.response?.data : err.message
      );
    commit("set_message", resp.message);
    commit("set_wallets", resp);
    await dispatch("metaMask");
  },

  async changeUserName({ commit, state }, data) {
    delete data.password;
    const resp = await this.$axios
      .$post("/api/change_username/" + state?.auth?.user?.id, data)
      .then((res) => {
        commit("set_username", data.username);
        return res;
      })
      .catch((err) =>
        err?.response?.data ? err?.response?.data : err.message
      );
    commit("set_message", resp.message);
  },

  async changePassword({ commit, state }, data) {
    data.id = state?.auth?.user?.id;
    data.token = state?.auth?.token;
    data.password = data.new_password;

    const resp = await this.$axios
      .$post("/api/reset_password", data)
      .catch((err) =>
        err?.response?.data ? err?.response?.data : err.message
      );
    commit("set_message", resp.message);
  },

  async changeImages({ commit, state }, data) {
    const resp = await this.$axios
      .$post("/api/upload_ava/" + state?.auth?.user?.id, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          "cache-control": "no-cache",
        },
      })
      .catch((err) =>
        err?.response?.data ? err?.response?.data : err.message
      );
    commit("set_message", resp.message);
    commit("set_avatar", resp.path);
  },

  async removeAva({ commit }) {
    const data = {};
    const resp = await this.$axios
      .$post("/api/remove_ava/", data)
      .catch((err) =>
        err?.response?.data ? err?.response?.data : err.message
      );

    commit("set_message", resp.message);
    commit("set_avatar", resp.path);
  },

  async connectedWallets({ commit }, data) {
    await commit("connectWallets", data);
  },

  async disconnectWallets({ commit }, data) {
    await commit("disconnectWallets", data);
  },

  async exchangeConnect({ commit }, data) {
    const resp = await this.$axios
      .$post(`${this.$config.API_SERVER_ETH}/api/cex/ping`, data)
      .catch((err) => {
        commit("set_message", err.response?.data?.message ?? err.message);
      });

    if (resp) {
      commit("set_message", {
        status: "success",
        message: "API key tested successfully",
      });
      return true;
    }
  },

  async getExchangeUser({ commit }, data = {}) {
    const resp = await this.$axios
      .$get(`${this.$config.API_SERVER_ETH}/api/cex/user`, data)
      .catch((err) =>
        err?.response?.data ? err?.response?.data : err.message
      );

    commit("set_exchange_user", resp);
  },

  async removeExchangeUser({ commit }, data) {
    const resp = await this.$axios
      .$delete(`${this.$config.API_SERVER_ETH}/api/cex/user`, { data })
      .catch((err) =>
        err?.response?.data ? err?.response?.data : err.message
      );
    commit("set_message", resp.message);
    if ("exchanges" in resp) {
      commit("set_exchange_user", resp.exchanges);
    }
  },

  async saveExchangeUser({ commit }, data) {
    data.exchange = data.cex;
    data.key = data.apiKey;
    const resp = await this.$axios
      .$post(`${this.$config.API_SERVER_ETH}/api/cex/user`, data)
      .catch((err) =>
        err?.response?.data ? err?.response?.data : err.message
      );
    commit("set_message", resp.message);
    if ("exchanges" in resp) {
      commit("set_exchange_user", resp.exchanges);
    }
  },

  async withdraw({ dispatch, state, commit }, data) {
    const resp = await this.$axios
      .$post(`${this.$config.API_SERVER_ETH}/api/activity/withdraw`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .catch((err) => err);
    return resp;
    // const message = state.withdraw[resp.data.starus][resp.data.message]

    // commit("set_message", resp.data.message);

    // if (resp.data?.message) {
    //   commit("set_log_exchange", resp.data.logs);
    // } else if (resp.data?.success) {
    //   commit(
    //     "set_message",
    //     `USDC successfully withdrawn and sent to \n ${data.addresses.join(
    //       "\n"
    //     )}`
    //   );
    // } else {
    //   commit("set_message", resp.data.message);
    // }
  },

  async setGAZ({ commit }, data) {
    commit("set_GAZ", data);
  },

  async getServices({ commit }) {
    const resp = await this.$axios
      .$get(`${this.$config.API_SERVER_ETH}/api/task/services`)
      .catch((err) =>
        err?.response?.data ? err?.response?.data : err.message
      );
    if (resp?.message) {
      commit("set_message", resp?.message);
    }
    commit("set_services", resp);
  },

  async getNetworks({ commit }) {
    const resp = await this.$axios
      .$get(`${this.$config.API_SERVER_ETH}/api/networks`)
      .catch((err) =>
        err?.response?.data ? err?.response?.data : err.message
      );
    if ("message" in resp) {
      commit("set_message", resp?.message);
    }
    commit(
      "set_networks",
      resp.map(
        (item) => (
          (item.chain_id = Number(BigInt(item.chain_id))),
          (item.rpc_url = item.rpc_url.map((el) => el.rpc_url)),
          (item.cex = item.cex[0]),
          item
        )
      )
    );
  },

  breadCrumbsRout({ commit }, data) {
    commit("set_breadCrumbs", data);
  },

  changeServices({ commit }, data) {
    commit("change_services", data);
  },

  async metaMask({ state, commit }) {
    let addresses = window.routs ? (await window.routs.request({ method: "eth_accounts" })).map((a) => a.toLowerCase()) : [];

    for (let a of state.wallets) {
      if (a.address.length < 66) {
        if (addresses.includes(a.address)) {
          await commit("connectWallets", a.address);
        } else {
          await commit("disconnectWallets", a.address);
        }
      }
    }

    await commit("sorting_wallets");
  },

  async get_stargateFinance({ commit }) {
    const resp = await this.$axios
      .$get("https://stargate.finance/api/trpc/getDefaultDstNativeAmount")
      .then((res) => res.result.data)
      .catch((err) => (console.log(err.message), 0));

    commit("set_stargateFinance", resp);
  },

  changeAwaitIdTask({ commit }, data) {
    commit("setAwaitIdTask", data);
  },

  setPathTransactions({ commit }, data) {
    commit("set_pathTransactions", data);
  },
};

export const getters = {
  isAuthenticated(state) {
    return state.auth.loggedIn;
  },

  wallets: (state) => state.wallets,
  services: (state) => state.services,

  loggedInUser(state) {
    return state.auth.user;
  },

  hashGenerator: (state) => (sumString) => {
    const symbolArr =
      "1234567890qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM";
    var randomString = "";
    for (let i = 0; i < sumString; i++) {
      var index = Math.floor(Math.random() * symbolArr.length);
      randomString += symbolArr[index];
    }
    return randomString;
  },

  checkPassword: (state) => (password) => {
    const passError = {
      one: false, //Be between 10-40 characters
      two: false, //Contain at least 1 number
      three: false, //Contain at least 1 uppercase letter
      four: false, //Contain at least 1 lowercase letter
      five: false, //Not start with 0x
      six: false, //Not contain # & + = or space
    };
    if (password.length > 9) passError.one = true;
    if (/\d/.test(password)) passError.two = true;
    if (/[A-Z]/.test(password)) passError.three = true;
    if (/[a-z]/.test(password)) passError.four = true;
    if (!password.startsWith("0x") && password.length > 1)
      passError.five = true;
    if (/[@$%^*?!~:;,./\\]/.test(password)) passError.six = true;
    return passError;
  },

  isPaidSubscription: (state) => () => {
    return state.auth.user.paid_subscription;
  },

  setPassword: (state) => (value) => {
    return (state.isPassword = value);
  },

  find_stargateFinance: (state) => (value) => {
    if (state.stargateFinance.length) {
      return state.stargateFinance.find((item) => item.chain === value)?.amount;
    } else {
      return 0;
    }
  },
};
