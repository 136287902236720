
export default {
  name: "Tooltip",
  components: {},
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    hover: false,
  }),
  computed: {},
  methods: {},
  watch: {},
  updated() {},
  mounted() {},
  created() {},
  destroyed() {},
};
