
export default {
  name: "Radio",
  components: {},
  props: {
    subData: {
      type: [String, Array, Object],
      default: () => "",
    },
    value: {
      type: String,
      default: "",
    },
    classList: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    checked: {
      type: [String, Boolean],
      default: false,
    },
    disabled: {
      type: [String, Boolean],
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    hashGenerator() {
      return this.$store.getters.hashGenerator;
    },
    keyInputComponent() {
      return this.$vnode.key ?? this.hashGenerator(6);
    },
  },
  methods: {
    submitValue(e) {
      if (this._events?.click) {
        e.subData = this.subData;
        this.$emit("click", e);
      }
    },
  },
  watch: {},
  updated() {},
  mounted() {},
  created() {},
  destroyed() {},
};
