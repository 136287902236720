
export default {
  name: "Pagination",

  props: {
    subData: {
      type: [String, Array, Object],
      default: () => "",
    },
    classList: {
      type: String,
      default: "",
    },
    page: {
      type: Number,
      default: 0,
    },
    pageLength: {
      type: [String, Number],
      default: 0,
    },
    pagination: {
      type: [Array, Object],
      default: () => [],
    },
  },

  data: () => ({
    selectPagination: 0,
  }),

  computed: {
    selected() {
      return this.pagination.indexOf(this.selectPagination) >= 0
        ? this.pagination.indexOf(this.selectPagination)
        : this.pagination.length - 1;
    },
  },

  methods: {
    inputPagination(e) {
      this.selectPagination =
        parseInt(e.target.options[e.target.selectedIndex].value) ||
        this.pageLength;
      sessionStorage.setItem("perPage", this.selectPagination);
      this.$emit("currentPage", { page: 0, perPage: this.selectPagination });
    },

    currentPage(e) {
      this.$emit("currentPage", {
        page: parseInt(e.target.innerText) - 1,
        perPage: this.selectPagination,
      });
    },
  },

  created() {
    const pagination = Number(
      sessionStorage.getItem("perPage", this.selectPagination)
    );
    this.selectPagination = pagination > 0 ? pagination : this.pagination[0];
    this.$emit("currentPage", { page: 0, perPage: this.selectPagination });
  },
};
