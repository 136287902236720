
export default {
  name: "ConnectedExchange",
  props: {
    wallet: {
      type: String,
      default: () => "",
    },
    showConnected: {
      type: Boolean,
      default: () => false,
    },
    showNetwoks: {
      type: Boolean,
      default: () => false,
    },
  },

  data: () => ({
    types: {
      1: "Evm",
      2: "Starknet",
    },
  }),

  computed: {
    wallets() {
      return this.$store.state.wallets;
    },
  },
  methods: {
    connected() {
      return this.wallets.find((item) => item.address === this.wallet)
        ?.connected;
    },
    in_progress() {
      if (this.$route.name === "cex") return false;
      return this.wallets.find((item) => item.address === this.wallet)
        ?.inProgress;
    },
    type_wallet() {
      return this.wallets.find((item) => item.address === this.wallet)
        .type_wallet;
    },
  },
};
