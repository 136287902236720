export default function ({ store, redirect }) {
  if (process.client) {
    const isFirstVisit = localStorage.getItem("isFirstVisit");

    if (!isFirstVisit) {
      localStorage.setItem("isFirstVisit", "true");
      return redirect("/profile/onboard");
    }
  }
}
