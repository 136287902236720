
import { mapActions, mapGetters } from "vuex";

export default {
  name: "WalletServices",

  props: {
    wallet: {
      type: [Array, Object],
      default: [],
    },
  },

  data: () => ({
    showBalansec: true,
    clipboardClass: false,
  }),

  computed: {
    ...mapGetters("plans", ["userSubscription"]),

    user() {
      return this.$store.state.auth.user;
    },

    wallets() {
      return this.$store.state.wallets;
    },
    isPaidSubscription() {
      return this.$store.getters.isPaidSubscription();
    },

    getServicesAmount() {
      const length = this.wallet.services?.length;

      return length === 10
        ? `Reached max numbers of services ${length}`
        : `Services: ${length}/10`;
    },
  },

  methods: {
    changeDexOrder(event) {
      const { oldOrder, newOrder } = event;
      // const { services, wallets } = this.config;

      if (!this.wallet?.services?.length) return;

      const targetIndex = Math.min(
        Math.max(newOrder, 0),
        this.wallet?.services?.length
      );

      const movedService = this.wallet?.services?.splice(oldOrder, 1)[0];
      this.wallet?.services?.splice(targetIndex, 0, movedService);
    },

    startTest(wallet) {
      if (this._events?.startTest) {
        this.$emit("startTest", wallet);
      }
    },

    deleteWallet(address) {
      if (this._events?.deleteWallet) {
        this.wallet.modified = true;
        this.$emit("deleteWallet", address);
      }
    },

    resetWallet(wallet) {
      if (this._events?.resetWallet) {
        this.wallet.modified = false;
        this.$emit("resetWallet", wallet);
      }
    },

    activityData({ name, type, value }, index) {
      let newValue = value;

      if (["range", "number"].includes(type) && name === "slippage") {
        newValue = Math.min(+value, 10);
      } else if (["range", "number"].includes(type) && name === "amount") {
        newValue = +value;
      } else if (
        ["checkbox", "number"].includes(type) &&
        ["dst_native", "dst_native_amount", "bridge_to"].includes(name)
      ) {
        newValue =
          type === "number"
            ? parseFloat(value) === 0
              ? ""
              : parseFloat(value)
            : value;
      }

      this.wallet.services[index][name] = newValue;

      this.wallet.status = false;
      this.wallet.isTest = null;
      this.wallet.modified = true;
    },

    async get_nft(e) {
      const response = await this.$axios
        .$post(`${this.$config.API_SERVER_ETH}/api/activity/bridge/nft`, {
          wallets: [this.wallet.address],
          service: "l2pass",
        })
        .then((res) => res)
        .catch((err) => (console.log(err.message), 0));

      if (response) {
        const service = this.wallet.services.find(
          (service) => service.key === e.key
        );

        service.nft_request = true;
        service.nft = response[this.wallet.address];

        const availableNfts = Object.keys(service.nft);

        if (!availableNfts.includes(service.network_from)) {
          service.network_from = availableNfts[0];
        }
      }
    },

    async get_current_balances(wallet) {
      const resp = await this.$axios
        .$post(`${this.$config.API_SERVER_ETH}/api/wallets/balances`, {
          wallets: wallet.address,
        })
        .then((res) => res)
        .catch((err) => (console.log(err.message), false));

      if (Object.keys(resp).length > 0) {
        for (const key of Object.keys(resp)) {
          const value = resp[key];

          for (let [key, chain] of Object.entries(value)) {
            chain.map(
              (item) =>
                (item.usd =
                  item.usd == 0 ? "<$0.01" : (item.usd = `$${item.usd}`))
            );
          }
          if (value) {
            this.wallet.allBalances = value;
          }
        }
      }
    },
    lockWallet(wallet) {
      wallet.locked = !wallet.locked;
    },
    classList() {
      return [
        this.wallet.loading ? "loading" : "",
        this.wallet.show ? "open" : "",
        this.wallet.isTest != null
          ? this.wallet.status
            ? "success"
            : "failed"
          : null,
      ];
    },
    openTab(wallet) {
      wallet.show = !wallet.show;
    },
    hiddenWallet(address) {
      if (typeof address != "object" && address?.length) {
        return `${address.slice(0, 6)}.....${address.substr(
          address.length - 6
        )}`;
      } else {
        return "";
      }
    },
    getETH_zk(address) {
      const balance = Number(
        this.wallets.find((item) => item.address === address).eth_zk
      );
      return balance > 0 ? balance.toFixed(4) : 0;
    },
    createLink(address) {
      return `https://debank.com/profile/${address}`;
    },
    getTags(address) {
      const tags = this.wallets
        .find((item) => item.address === address)
        .tags.split(",");
      return tags.length ? tags : [];
    },
    shuffleService() {
      this.wallet.services = this.shuffle([...this.wallet.services]);
      this.wallet.status = false;
      this.wallet.isTest = null;
      this.wallet.modified = true;
    },
    swapPlaces(changing) {
      this.wallet.status = false;
      this.wallet.isTest = null;
      this.wallet.modified = true;

      const currentServises = this.wallet.services.find(
        (serv) => serv.key == changing.key
      );

      if (
        changing.type === "bridge_tokens" ||
        changing.type == "bridge_token" ||
        changing.type === "bridge_refuel" ||
        changing.type === "bridge_nft"
      ) {
        currentServises.network_from = changing.network_to;
        currentServises.network_to = changing.network_from;
      }

      if (changing.type !== "bridge_token") {
        currentServises.token_in = changing.token_out;
        currentServises.token_out = changing.token_in;
      }
    },
    deleteService(serviceKey) {
      this.wallet.services = this.wallet.services.filter(
        (item) => item.key !== serviceKey
      );

      this.wallet.status = false;
      this.wallet.isTest = null;
      this.wallet.modified = true;
    },
    shuffle(array) {
      this.wallet.status = false;
      this.wallet.isTest = null;
      this.wallet.modified = true;
      let currentIndex = array.length;
      let randomIndex = 0;
      while (currentIndex > 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }
      return array;
    },

    // changeOrder(e) {
    //   const { oldOrder, newOrder } = event;

    //   this.wallet.status = false;
    //   this.wallet.isTest = null;
    //   this.wallet.modified = true;

    //   const services = [...this.wallet.services];

    //   if (newOrder >= services.length) {
    //     var k = newOrder - services.length + 1;
    //     while (k--) {
    //       services.push(undefined);
    //     }
    //   }

    //   services.splice(newOrder, 0, services.splice(oldOrder, 1)[0]);
    //   this.wallet.services = services;
    // },

    copyToClipboard(address) {
      this.$tableFormat.copyToClipboard(address);
      this.clipboardClass = true;
      setTimeout(() => (this.clipboardClass = false), 1000);
    },
    clearProxy(wallet) {
      const proxy = this.wallets.find(
        (wall) => wall.address === wallet.address
      ).proxy;
      if (wallet.proxy?.length) {
        wallet.proxy = "";
      } else if (proxy?.length) {
        wallet.proxy = proxy;
      } else {
        this.$store.dispatch("setMessage", {
          status: "warning",
          message: "Proxy is not attached to the wallet",
        });
      }
    },
    showBalanse() {
      this.showBalansec = !this.showBalansec;
    },
  },

  mounted() {
    this.wallet.show = this.wallet.services.length > 0;
  },
};
