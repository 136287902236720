
import { mapGetters } from "vuex";

export default {
  name: "TableComponent",

  props: {
    config: {
      type: Object,
      default: () => {},
    },
    searchValue: {
      type: String,
      default: () => "",
    },
    status: {
      type: [Array, Object],
      default: () => {},
    },
  },

  data() {
    return {
      thead: [],
      tbody: {},
      getBalanceWallet: {},
      changeItem: {},
      changeProxy: {},
      listCheck: [],
      opacity: [],
      lastShowtd: 1,
      clipboardClass: -1,
      page: 0,
      showPage: 0,
      perPage: 10,
      pageLength: 0,
      pagination: [10, 30, 50, "All"],
      type_wallet: {
        1: "zkSync Era",
        2: "Starknet",
      },
      isLoading: false,
      allBalanceWallStart: false,
      showAllBalance: true,
      allBalanceWallets: 0,
      lastAllBalanceWallets: 0,
      sortAscending: true,
    };
  },

  computed: {
    ...mapGetters("plans", ["userSubscription"]),

    user() {
      return this.$store.state.auth.user;
    },

    wallets() {
      return this.$store.state.wallets;
    },

    showChecked() {
      return this.listCheck.length || this.pageLength;
    },

    isTasksTable() {
      return this.config.thead.includes("Proxy");
    },

    checkedWalletsLength() {
      const checkedWallets = this.wallets.filter((el) => el.checked);
      return checkedWallets ? checkedWallets.length : 0;
    },
  },

  methods: {
    dateFormat(newDate) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      const pattern = /(\d{2})\.(\d{2})\.(\d{4}),\s(\d{2}):(\d{2}):(\d{2})/;
      const date = new Date(newDate.replace(pattern, "$3-$2-$1 $4:$5:$6"));

      return date.toLocaleDateString("en-GB", options);
    },

    isOpacity(address) {
      return this.opacity.includes(address);
    },

    isCheckboxDisabled(wallet) {
      const { inProgress, connected, checked } = wallet;

      // Блокирую чекбокс, если он в таблице с тасками и/или:
      // 1) Не подключён
      // 2) Уже задействован в таске
      // 3) Выбрано до 20 кошельков
      return (
        this.isShowWallet("Proxy") &&
        (inProgress ||
          !connected ||
          (this.checkedWalletsLength > 49 && !checked))
      );
    },

    async checkedWallet(address, checked, isAll = true) {
      const wallet = this.wallets.find((el) => el.address === address);

      await this.$store.dispatch("checkedWallet", { address, checked });

      if (this.checkedWalletsLength === 50) {
        this.$store.dispatch("setMessage", {
          status: "warning",
          message: "50 wallets maximum",
        });
      }

      if (
        this.isTasksTable &&
        !wallet?.connected &&
        this.checkedWalletsLength > 50
      )
        return;

      if (this.allBalanceWallStart) {
        this.allBalanceWallStart = false;
        const tmp = this.allBalanceWallets;
        this.lastAllBalanceWallets = tmp;
        this.allBalanceWallets = 0;
      }

      const balance = +this.wallets.find((wall) => wall.address == address)
        .balance;

      if (this.listCheck.includes(address)) {
        this.listCheck.splice(this.listCheck.indexOf(address), 1);
        this.allBalanceWallets -= balance;
      } else if (checked) {
        this.listCheck.push(address);
        this.allBalanceWallets += balance;
      }

      if (!this.listCheck.length) {
        this.opacity = [];
        const tmp = this.lastAllBalanceWallets;
        this.allBalanceWallets = tmp;
        this.allBalanceWallStart = true;
      } else {
        this.opacity = this.tbody.arr
          .filter((w) => !w.checked)
          .map((w) => w.address);
      }

      if ((this._events?.checkedWallet, isAll)) {
        this.$emit("checkedWallet", this.listCheck);
      }
    },

    async checkAllWallet(e) {
      const filteredWallets = this.tbody?.arr.filter((obj) => !obj.inProgress);
      const length = Math.min(filteredWallets?.length, 50);

      for (let i = 0; i < length; i++) {
        const wallet = filteredWallets[i];

        if (
          (!this.listCheck.includes(wallet.address) || !e.target.checked) &&
          wallet.connected &&
          !wallet.inProgress
        ) {
          this.checkedWallet(wallet.address, e.target.checked, false);
        }
      }

      if (this._events?.checkedWallet) {
        this.$nuxt.$loading.start();
        await new Promise((resolve) => setTimeout(resolve, 300));
        this.$emit("checkedWallet", this.listCheck);
      }
    },

    unCheckedAllWallets() {
      const checkedWallet = this.wallets.filter((w) => w.checked);
      for (const wallet of checkedWallet) {
        this.$store.dispatch("checkedWallet", {
          address: wallet.address,
          checked: false,
        });
      }

      this.listCheck = [];
      this.opacity = [];
    },
    async changeValueTags(index) {
      if (!this.isOpacity(this.tbody.arr[index].address)) {
        this.changeItem = { ...this.tbody.arr[index] };
      }
    },
    async changeValueProxy(index) {
      if (!this.isOpacity(this.tbody.arr[index].address)) {
        this.changeProxy = { ...this.tbody.arr[index] };
      }
    },
    async changeTags(e, address) {
      if (!this.isOpacity(address)) {
        await this.$store.dispatch("changeWallets", {
          value: e.target.value,
          name: "tags",
          key: this.changeItem.address,
        });
        this.changeItem = {};
      }
    },
    async changeProxys(e, address) {
      if (!this.isOpacity(address)) {
        const str = e.target.value;
        const regex =
          /(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})?:(\d{1,6})?:(.+)?:(.+)/i;

        if (regex.test(str)) {
          await this.$store.dispatch("changeWallets", {
            value: str,
            name: "proxy",
            key: this.changeProxy.address,
          });
        } else {
          this.$store.dispatch("setMessage", {
            status: "warning",
            message: "Fill your data up ",
          });
        }
        this.changeProxy = {};
      }
    },
    async deleteProxy(address) {
      await this.$store.dispatch("changeWallets", {
        value: "",
        name: "proxy",
        key: address,
      });
      this.changeProxy = {};
    },
    async deleteTags(address) {
      await this.$store.dispatch("changeWallets", {
        value: "",
        name: "tags",
        key: address,
      });
      this.changeItem = {};
    },
    enterData() {
      this.changeItem = {};
      this.changeProxy = {};
    },
    showProxy(proxy) {
      return proxy.split(":")[0];
    },
    async changeDWallet(e, wallet) {
      const address = e.target.value;
      const regex = /[0x][A-Za-z0-9]{41,43}/i;

      if (
        address.length - 1 >= 41 &&
        address.length - 1 <= 43 &&
        regex.test(address)
      ) {
        let value = address.match(regex)[0];
        if (!/^0/.test(value)) {
          value = "0" + value;
        }

        await this.$store.dispatch("changeWallets", {
          value: value,
          name: "deposit_wallets",
          key: wallet,
        });
      } else if (address.length <= 0) {
        await this.$store.dispatch("changeWallets", {
          value: "",
          name: "deposit_wallets",
          key: wallet,
        });
      } else {
        this.$store.dispatch("setMessage", {
          status: "warning",
          message: "does not meet the parameters",
        });
      }
    },
    async deleteWallet(wallet = "") {
      this.$store.dispatch("removeWallet", {
        key: this.listCheck.length ? this.listCheck : wallet,
      });

      this.listCheck = [];
      this.opacity = [];
    },
    isShowWallet(key) {
      return this.thead.indexOf(key) != -1;
    },
    createLink(address) {
      if (!this.isOpacity(address)) {
        return `https://debank.com/profile/${address}`;
      }
    },

    hiddenWallet(address) {
      if (typeof address === "string" && address.length) {
        return `${address.slice(0, 6)}.....${address.slice(-6)}`;
      }
      return "";
    },

    toggleAddressVisibility(address) {
      const index = this.tbody?.arr?.findIndex((el) => el.address === address);

      if (index !== -1) {
        const target = this.tbody.arr[index];

        target.isVisible =
          target.isVisible === undefined
            ? !this.user.isWalletsHidden
            : !target.isVisible;
      }
    },

    sortWallets(type) {
      const functions = {
        Tag: (a, b) =>
          a.tags.localeCompare(b.tags, undefined, {
            numeric: true,
            sensitivity: "base",
          }),

        Balance: (a, b) => a.balance - b.balance,

        "Last Activity": (a, b) =>
          new Date(a.last_activity).getTime() -
          new Date(b.last_activity).getTime(),
      };

      const sortFunction = functions[type];

      if (sortFunction) {
        this.tbody.arr.sort((a, b) =>
          this.sortAscending ? sortFunction(a, b) : sortFunction(b, a)
        );

        this.sortAscending = !this.sortAscending;
      }
    },

    async getBalance(wallet = {}) {
      this.isLoading = true;
      const wallets = [];

      if (
        this.isOpacity(wallet.address) ||
        this.getBalanceWallet[wallet.address]
      ) {
        return;
      }

      this.getBalanceWallet[wallet.address] = true;

      if (!("address" in wallet)) {
        // запрос всех видимых кошельков
        for (const wallet of this.tbody.arr) {
          wallets.push(wallet.address);
        }
      } else {
        // запрос на 1 кошелек
        wallets.push(wallet.address);
      }

      if (wallets.length) {
        const balance = await this.$axios
          .$post(`${this.$config.API_SERVER_ETH}/api/wallets/balance`, {
            wallets,
          })
          .then((res) => res)
          .catch((err) => (console.log(err.message), 0));

        if (balance) {
          await this.$store.dispatch("changeWallets", {
            value: Object.keys(balance).map((item, indx) => ({
              key: item,
              val: balance[item],
            })),
            name: "balance",
          });

          Object.keys(balance).forEach((item) =>
            this.$refs[`anim=${item}`][0]?.classList.add("animation")
          );
          setTimeout(
            () =>
              Object.keys(balance).forEach((item) =>
                this.$refs[`anim=${item}`][0]?.classList.remove("animation")
              ),
            3000
          ); // .animation

          this.isLoading = false;
          this.allBalanceWallStart = false;
          this.getBalanceWallet[wallet.address] = false;
        } else {
          this.$store.dispatch("setMessage", JSON.stringify(balance));
          this.isLoading = false;
          this.getBalanceWallet[wallet.address] = false;
        }
      }
    },
    copyToClipboard(address, index) {
      if (!this.isOpacity(address)) {
        this.$tableFormat.copyToClipboard(address);
        this.clipboardClass = index;
        setTimeout(() => (this.clipboardClass = -1), 1000);
      }
    },
    currentPage(e) {
      this.page = e.page;
      this.perPage = e.perPage;
      this.unCheckedAllWallets();
    },
    paginationTbody() {
      const wallets = [...this.wallets];
      if (!this.allBalanceWallStart && wallets.length > 0) {
        this.allBalanceWallets = 0;
        wallets.forEach(
          (wall) => (this.allBalanceWallets += Number(wall.balance))
        );
        this.allBalanceWallStart = true;
      }

      this.tbody = this.$tableFormat.pagination(
        wallets,
        this.page,
        this.perPage,
        this.config?.typeWallet || 0
      );
      this.pageLength = this.tbody.length;
    },
    gotoPage() {
      this.$router.push("/profile/add-wallets");
    },
    createrTags(tegs) {
      return tegs?.split(",") ?? "";
    },
    createrProxy(proxy) {
      return proxy?.split(",") ?? "";
    },
    clearKeyESC(e) {
      if (e.key === "Escape") {
        this.unCheckedAllWallets();
      } else if (e.ctrlKey && e.key === "z") {
        this.checkedWallet(
          this.listCheck[this.listCheck.length - 1],
          false,
          false
        );
      }
    },
    getStatusWallet(address) {
      return (
        this.status?.flat().find((w) => w.address === address)?.status ?? ""
      );
    },
    async checkProxy() {
      const listAddresses = this.tbody.arr
        .filter((wall) => wall.proxy?.length)
        .map((wall) => wall.address);

      for (const address of listAddresses) {
        const resp = await this.$axios
          .$post(`${this.$config.API_SERVER_ETH}/api/wallets/proxy/ping`, {
            wallets: [address],
          })
          .then((res) => res)
          .catch((err) => false);

        await this.$store.dispatch("changeWallets", {
          name: "proxy_status",
          value: resp.success.map((item) => ({
            key: item,
            val: true,
          })),
        });
        await this.$store.dispatch("changeWallets", {
          name: "proxy_status",
          value: resp.failed.map((item) => ({
            key: item,
            val: false,
          })),
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));

        setTimeout(async () => {
          await this.$store.dispatch("changeWallets", {
            name: "proxy_status",
            value: resp.success.map((item) => ({
              key: item,
              val: 2,
            })),
          });
        }, 3000); // .animation
      }
    },
  },

  watch: {
    searchValue(newVal, oldVal) {
      if (newVal.length > 2) {
        this.tbody = this.$tableFormat.search(
          this.wallets,
          newVal,
          this.config?.searchColl,
          this.page,
          this.perPage,
          this.config?.typeWallet || 0
        );
        this.pageLength = this.tbody.length;
      } else if (newVal.length === 0) {
        this.paginationTbody();
      }
    },
    perPage(newVal, oldVal) {
      this.paginationTbody();
      this.listCheck = [];
      this.opacity = [];
    },
    page(newVal, oldVal) {
      this.paginationTbody();
    },
    wallets(newVal, oldVal) {
      if (
        JSON.stringify(newVal) == JSON.stringify(oldVal) ||
        newVal.some((item) => item.connected)
      ) {
        newVal.forEach((wall) => (this.getBalanceWallet[wall.address] = false));

        this.paginationTbody();
        this.listCheck = [];
        this.opacity = [];
        this.$emit("checkedWallet", []);
      }
    },
  },

  mounted() {
    this.thead = [...this.config?.thead] ?? [];
    if (this.config?.checkbox) {
      this.thead.unshift({ checkbox: true });
    }

    this.paginationTbody();
    document.addEventListener("keyup", this.clearKeyESC);
  },

  async created() {
    if (this.$route.name === "cex") this.showAllBalance = false;
    await this.$store.dispatch("getWallets");
  },

  destroyed() {
    this.unCheckedAllWallets();
  },
};
