
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Logs",

  props: {
    isPause: {
      type: Boolean,
      default: () => false,
    },
    newTaskButton: {
      type: Boolean,
      default: () => false,
    },
    isRestoreFailed: {
      type: Boolean,
      default: () => false,
    },
  },

  data: () => ({
    taskId: 0,
    currentTask: {},
    tabs: [],
    currentServices: {},
    clipboardClass: -1,
    updatingComponent: false,
    timingReload: false,
    walletsDate: {},
    parallelDate: new Date(),
    progressServices: {},
    timeDelayCounter: false,
    countdown: 0,
    selectedServices: [],

    reloadDelayInterval: null,

    // timeouts: [5 * 1000, 15 * 1000, 60 * 1000, 3 * 60 * 1000],

    editableWallet: "",
    newTag: "",

    errors: [
      "on_chain_error",
      "already_known_error",
      "insufficient_balance_error",
      "not_sent_error",
    ],
  }),

  computed: {
    ...mapGetters("tasks", ["task", "transactionsStatuses"]),

    isAdmin() {
      return this.$store.state.auth?.user.is_admin;
    },

    user() {
      return this.$store.state.auth.user;
    },

    wallets() {
      return this.$store.state.wallets;
    },

    getWallets() {
      if (this.currentTask?.address) {
        this.currentTask?.address.forEach((item) =>
          typeof this.walletsDate[item] === "undefined"
            ? (this.walletsDate[item] = {})
            : null
        );
      }
      return this.currentTask?.address ?? [];
    },

    services() {
      return this.$store.state.services;
    },

    networks() {
      return this.$store.state.networks;
    },

    isSelectAll() {
      const statuses = ["finished", "canceled"];
      return statuses.includes(this.currentTask.status);
    },

    isSelectFailed() {
      const isErrorTask = this.currentTask?.completed.some((el) =>
        this.errors.includes(el.status)
      );

      return this.isSelectAll && isErrorTask;
    },
  },

  methods: {
    ...mapActions("tasks", ["getTask", "getTaskTransactions"]),

    timerDelay() {
      clearInterval(this.reloadDelayInterval);
      const statuses = ["done", "error", "finished", "canceled"];

      if (
        this.currentTask.e_t_h_sign &&
        !statuses.includes(this.currentTask.status)
      ) {
        this.runDelays();
      }
    },

    async runDelays() {
      this.reloadDelayInterval = setInterval(
        () => this.getTaskTransactions(this.taskId),
        10000
      );
      // this.timeouts.shift() || 3 * 60 * 1000
    },

    getServiceDelay(delay) {
      const seconds = delay / 1000;
      return seconds < 2 ? "No delay" : `${seconds} sec delay`;
    },

    isServiceNameShown(type, name) {
      return (
        (type === "mint" && name === "l2pass") ||
        (type === "bridge_token" && name === "orbiter") ||
        (type === "bridge_token" && name === "relay") ||
        (type === "bridge_tokens" && name === "stargate") ||
        (type === "bridge_refuel" && name === "merkly") ||
        (type === "bridge_refuel" && name === "l2pass") ||
        (type === "bridge_nft" && name === "l2pass")
      );
    },

    getTag(address) {
      const wallet = this.wallets.find((obj) => obj.address === address);

      return wallet ? wallet.tags : "";
    },

    editTag(address) {
      this.editableWallet = address;
    },

    async changeTag(address) {
      await this.$store.dispatch("changeWallets", {
        value: this.newTag,
        name: "tags",
        key: address,
      });

      this.editableWallet = "";
    },

    formatStatus(status) {
      switch (status) {
        case "progress":
          return "In progress";
        case "pending":
          return "Pending";
        case "delay_waiting":
          return "Waiting for the delay";
        case "crosschain_waiting":
          return "Waiting for the funds on the target chain";
        case "gas_waiting":
          return "Waiting for a lower gas price (max 6 hour)";
        case "gas_waiting_error":
          return "The gas price was above the specified limit";
        case "on_chain_error":
          return "Blockchain error occurred";
        case "already_known_error":
          return "The order of transactions is broken";
        case "insufficient_balance_error":
          return "Insufficient balance";
        case "not_sent_error":
          return "Transaction not sent";
        default:
          return "Unknown status";
      }
    },

    isProgressService(service) {
      return ![undefined, null, "", "waiting", "done"].includes(service.status);
    },

    findDate(address, service, start = false) {
      if (service.start_date !== null) {
        this.parallelDate = service.start_date;
        this.walletsDate[address].start = service.start_date;
        return this.dateFormat(service.start_date);
      } else if (service.end_date !== null) {
        this.walletsDate[address].end = service.end_date;
        return this.dateFormat(service.end_date);
      } else {
        const delays = {};
        for (const address of Object.keys(this.currentServices)) {
          delays[address] = this.currentServices[address].map(
            (serv) => serv.delays
          );
        }
        try {
          if (this.currentTask.parallel) {
            const allDelays = Object.keys(delays)
              .map((addr) => delays[addr])
              .flat();
            if (start) {
              const startDate =
                typeof this.parallelDate === "string"
                  ? new Date(this.parallelDate)
                  : this.parallelDate;
              return `${this.dateFormat(startDate.toString())}*`;
            } else {
              const endDate = new Date(
                new Date(this.parallelDate).getTime() +
                  allDelays.reduce((x, y) => x + y, 0)
              );
              return `${this.dateFormat(endDate.toString())}*`;
            }
          }

          // Если не параллельное выполнение
          if (!this.currentTask.parallel) {
            if (start) {
              if (typeof this.walletsDate[address].start == "undefined") {
                this.walletsDate[address].start = new Date();
              }

              return `${this.dateFormat(
                this.walletsDate[address].start.toString()
              )}*`;
            } else {
              if (typeof this.walletsDate[address].end == "undefined") {
                const startDate = new Date(this.walletsDate[address].start);
                this.walletsDate[address].end = new Date(
                  startDate.getTime() +
                    (delays[address].length - 1 * 1000) +
                    delays[address].reduce((x, y) => x + y, 0)
                );
              }

              return `${this.dateFormat(
                this.walletsDate[address].end.toString()
              )}*`;
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    },

    get_processed(address) {
      return this.currentTask?.completed
        ? this.currentTask?.completed
            .map((item) => (item.address === address ? item.processed : null))
            .filter((item) => item === 1).length
        : 0;
    },

    get_failed(address) {
      return this.currentTask?.completed
        ? this.currentTask?.completed
            .map((item) => (item.address === address ? item.failed : null))
            .filter((item) => item === 1).length
        : 0;
    },

    getServices(address) {
      return (
        this.currentTask.services.filter((serv) => serv.address === address) ??
        []
      );
    },

    get_project_icon(service) {
      if (!service || !service.project_icon) return undefined;

      const currentProjectIcon =
        service.currentProject && service.project_icon[service.currentProject];

      return (
        currentProjectIcon ??
        service.project_icon[Object.keys(service.project_icon)[0]]
      );
    },

    get_project_name(service) {
      return (
        service?.project_name[service?.currentProject] ??
        service?.project_name[Object.keys(service?.project_name)[0]]
      );
    },

    getTags(address) {
      const tags =
        this.wallets
          .find((item) => item.address === address)
          ?.tags?.split(",") ?? [];
      return tags?.length > 0 ? tags : [];
    },

    createLink(address) {
      return `https://debank.com/profile/${address}`;
    },

    hashlink(hash, transaction) {
      if (transaction && JSON.parse(transaction)) {
        const transact = JSON.parse(transaction);
        const explorer = this.networks.find(
          (item) => item.chain_id === this.fingChainId(transact)
        )?.explorer;
        return `${explorer}tx/${hash}`;
      } else {
        return "";
      }
    },

    openTabs(address) {
      if (this.tabs.includes(address)) {
        this.tabs.splice(this.tabs.indexOf(address), 1);
      } else {
        this.tabs.push(address);
      }
    },

    hiddenWallet(address) {
      if (typeof address != "object" && address?.length) {
        return `${address.slice(0, 6)}.....${address.substr(
          address.length - 6
        )}`;
      } else {
        return "";
      }
    },

    copyToClipboard(address, index) {
      this.$tableFormat.copyToClipboard(address);
      this.clipboardClass = index;
      setTimeout(() => (this.clipboardClass = -1), 1000);
    },

    dateFormat(newDate) {
      if (typeof newDate !== "string") {
        return "not date";
      }

      const options = {
        // year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const pattern = /(\d{2})\.(\d{2})\.(\d{4}),\s(\d{2}):(\d{2}):(\d{2})/;
      const date = new Date(
        new Date(newDate).toISOString().replace(pattern, "$3-$2-$1 $4:$5:$6")
      );

      return date.toLocaleDateString("en-GB", options);
    },

    setTask() {
      if (!this.task) return;

      this.currentTask = this.task;
      clearInterval(this.reloadDelayInterval);

      const servicesTask = (this.currentTask.services || []).filter(
        (item) => item.address !== null
      );

      const serviceBase = servicesTask
        .map((item) => {
          const service = this.services.find(
            (ser) => ser.id === item.services_id
          );

          if (service) delete service.status;
          return service;
        })
        .filter((item) => item);

      const currentServices = servicesTask.map((item) => ({
        currentProject: item.project_name,
        ...item,
        ...(serviceBase.find((ser) => ser.id === item.services_id) || {}),
        ...(this.currentTask.completed.find(
          (ser) => ser.key === item.key && ser.address === item.address
        ) || {}),
      }));

      this.getWallets.forEach(
        (address) =>
          (this.currentServices[address] = currentServices.filter(
            (item) => item.address === address
          ))
      );

      this.timerDelay();
    },

    fingChainId(transaction) {
      let chainId = false;
      for (const [key, value] of Object.entries(transaction)) {
        if (key === "chainId") {
          return value;
        } else if (typeof value === "object") {
          chainId = this.fingChainId(value);
        }
      }
      return chainId;
    },

    selectFromWallet(address, isAll = false) {
      const services = this.currentServices[address];
      const failedServices = this.currentServices[address].filter((item) =>
        this.errors.includes(item.status)
      );

      if (isAll) {
        // Select all
        // Если выбранные сервисы уже есть в массиве
        const areSelected = services.every((el) =>
          this.selectedServices.some((item) => item.id === el.id)
        );

        if (areSelected) {
          // Если уже выбраны - удалить
          this.selectedServices = this.selectedServices.filter(
            (el) => !services.some((item) => item.id === el.id)
          );
        } else {
          // Если НЕ выбраны - добавить
          services.forEach((service) => {
            const isSelected = this.selectedServices.find(
              (el) => el.id === service.id
            );
            if (!isSelected) this.selectedServices.push(service);
          });
        }
      } else {
        // Select failed
        // Если выбранные сервисы с фейлами уже есть в массиве
        const areFailedSelected = failedServices.every((el) =>
          this.selectedServices.some((item) => item.id === el.id)
        );

        // Если уже выбраны - удалить
        this.selectedServices = this.selectedServices.filter(
          (el) => !services.some((item) => item.id === el.id)
        );

        if (areFailedSelected) {
          // Удалить сервисы с фейлами
          this.selectedServices = this.selectedServices.filter(
            (el) => !failedServices.some((item) => item.id === el.id)
          );
        } else {
          // Добавить сервисы с фейлами
          failedServices.forEach((service) => {
            const isSelected = this.selectedServices.find(
              (el) => el.id === service.id
            );
            if (!isSelected) this.selectedServices.push(service);
          });
        }
      }

      // Emit event with the length of selectedServices
      this.$emit("on-check", this.selectedServices.length);
    },

    selectService(e, service) {
      if (e.target.checked) {
        this.selectedServices.push(service);
      } else {
        this.selectedServices.splice(this.selectedServices.indexOf(service), 1);
      }

      this.$emit("on-check", this.selectedServices.length);
    },
  },

  watch: {
    async transactionsStatuses(newVal, oldVal) {
      const areArraysEqual = newVal.every(
        (value, index) => value === oldVal[index]
      );

      if (!areArraysEqual) await this.getTask(this.taskId);
    },

    task: {
      handler: async function (newVal, oldVal) {
        if (
          typeof JSON.stringify(oldVal) !== "undefined" &&
          JSON.stringify(newVal) !== JSON.stringify(oldVal)
        ) {
          this.setTask();
        }
      },
      deep: true,
      immediate: true,
    },

    isPause(newVal, oldVal) {
      if (newVal === false) this.runDelays();
    },

    async newTaskButton(newVal, oldVal) {
      if (newVal !== oldVal && "newTaskFrom" in this._events) {
        if (!this.selectedServices.length) {
          for (const [key, value] of Object.entries(this.currentServices)) {
            for (const item of value) {
              this.selectedServices.push(item);
            }
          }
        }

        // Переподключение кошельков перед созданием таски
        await this.$store.dispatch("getWallets");

        // Подключены ли кошельки
        const areWalletsConnected = this.selectedServices.every((el) => {
          const index = this.wallets.findIndex(
            (item) => item.address === el.address
          );
          return this.wallets[index].connected;
        });

        // Если подключены - создать таску, если нет - показать сообщение юзеру
        if (areWalletsConnected) {
          this.$emit("newTaskFrom", this.selectedServices);
        } else {
          this.$store.dispatch("setMessage", {
            status: "warning",
            message: "Wallets are disconnected",
          });
        }

        clearInterval(this.timingReload);
        this.timingReload = false;
      } else {
        this.$store.dispatch("setMessage", {
          status: "warning",
          message: "No services are selected",
        });
      }
    },

    async isRestoreFailed(newVal, oldVal) {
      if (newVal !== oldVal && "newTaskFrom" in this._events) {
        // Выбрать все сервисы с ошибками
        this.getWallets.forEach((wallet) => {
          this.currentServices[wallet].forEach((service) => {
            if (this.errors.includes(service.status)) {
              this.selectedServices.push(service);
            }
          });
        });

        if (this.selectedServices.length) {
          // Переподключение кошельков перед созданием таски
          await this.$store.dispatch("getWallets");

          // Подключены ли кошельки
          const areWalletsConnected = this.selectedServices.every((el) => {
            const index = this.wallets.findIndex(
              (item) => item.address === el.address
            );
            return this.wallets[index].connected;
          });

          // Если подключены - создать таску, если нет - показать сообщение юзеру
          if (areWalletsConnected) {
            this.$emit("newTaskFrom", this.selectedServices);
          } else {
            this.$store.dispatch("setMessage", {
              status: "warning",
              message: "Wallets are disconnected",
            });
          }

          clearInterval(this.timingReload);
          this.timingReload = false;
        } else {
          this.$store.dispatch("setMessage", {
            status: "warning",
            message: "No failed tasks",
          });
        }
      } else {
        this.$store.dispatch("setMessage", {
          status: "warning",
          message: "No services are selected",
        });
      }
    },
  },

  mounted() {
    this.taskId = parseInt(this.$route?.params?.id);
    this.setTask();
  },

  beforeDestroy() {
    clearInterval(this.reloadDelayInterval);
    clearInterval(this.timingReload);
    clearInterval(this.timeDelayCounter);
    this.timingReload = false;
  },

  unmounted() {
    clearInterval(this.reloadDelayInterval);
    clearInterval(this.timingReload);
    clearInterval(this.timeDelayCounter);
    this.timingReload = false;
  },
};
