
export default {
  name: "Textarea",

  props: {
    subData: {
      type: [String, Array, Object],
      default: () => "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    classList: {
      type: String,
      default: "",
    },
  },

  computed: {
    hashGenerator() {
      return this.$store.getters.hashGenerator;
    },
    nameInput() {
      return this.$vnode.key ? this.$vnode.key : this.hashGenerator(10);
    },
  },

  methods: {
    submitValue(e) {
      if (e.type in this._events) {
        e.subData = this.subData;
        this.$emit(e.type, e);
      }
    },
  },
};
