export default ({ app, store }, inject) => {
  inject("tableFormat", {
    isSorted: '',
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
      document.body.removeChild(textArea);
    },
    copyToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(function () {
        console.log('Async: Copying to clipboard was successful!');
      }, function (err) {
        console.error('Async: Could not copy text: ', err);
      });
    },
    search(data, searching, searchColl, page, perPage, typeWallet){
      const arr = []
      data.forEach((line) => {
        if (searchColl.map(ind => line[ind]).filter((item) => item.search(new RegExp(searching, "i")) != -1 ? item : false).length > 0) {
          arr.push(line)
        }
      })
      return this.pagination(arr, page, perPage, typeWallet)
    },
    sorting(data, field, sortBy, newSortPage) {
      if (newSortPage) this.isSorted = ''
      const arr = [...data]
      const fieldId = {
        "Wallets": 'address',
        "Tag": 'tags',
        'Deposit amount': 'deposit_amount',
        'Deposit wallet': 'deposit_wallet',
        'Balance': 'balance',
        'Last Activity': 'last_activity'
      }[field]

      if (this.isSorted === sortBy) {
        arr.reverse()
      } else {
        function compare(a, b) {
          const toNumber = str => parseInt(str, 10);

          const numA = isNaN(toNumber(a)) ? a : toNumber(a);
          const numB = isNaN(toNumber(b)) ? b : toNumber(b);

          if (!isNaN(numA) && !isNaN(numB)) {
              return numA - numB;
          } else if (!isNaN(numA) && isNaN(numB)) {
              return -1;
          } else if (isNaN(numA) && !isNaN(numB)) {
              return 1;
          } else {
              return a.localeCompare(b);
          }
        }

        switch (sortBy) {
          case 'text':
            // arr.sort((a, b) => a[fieldId].localeCompare(b[fieldId]))
            arr.sort((a, b) => compare(a[fieldId], b[fieldId]))
            this.isSorted = 'text'
            break;
          case 'integer':
            arr.sort((a, b) => a[fieldId] - b[fieldId])
            this.isSorted = 'integer'
            break;
          case 'float':
            arr.sort((a, b) => parseFloat(a[fieldId]) - parseFloat(b[fieldId]))
            this.isSorted = 'float'
            break;
          case 'date':
            arr.sort((a, b) => new Date(a[fieldId]).getTime() - new Date(b[fieldId]).getTime())
            this.isSorted = 'date'
            break;

          default:
            console.log('sorting parameter not found');
            break;
        }
      }

      return arr
    },
    pagination(data, page, perPage, typeWallet=0){
      const start = page * (perPage > 0 ? perPage : data.length)
      const end = start + (perPage > 0 ? perPage : data.length )
      const arr = []
      const row = data.filter(item => item?.type_wallet === typeWallet)
      const table = (typeWallet == 0 ? data : row)

      table.forEach((row, index) => {
        if (!(index < start || index >= end)) {
          arr.push(row)
        }
      })
      return { arr, length: table.length }
    }
  });
};
