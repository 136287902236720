export const state = () => ({
  tasks: [],
  task: null,
  transactions: [],

  chosenTaskDate: null,
});

export const mutations = {
  saveTasks(state, tasks) {
    state.tasks = tasks;
  },

  saveTask(state, task) {
    state.task = task;
  },

  saveTransactions(state, transactions) {
    state.transactions = transactions;
  },

  deleteTask(state, taskId) {
    const index = state.tasks.findIndex((el) => el.id === taskId);
    if (index) state.tasks.splice(index, 1);
  },

  resetStore(state) {
    // state.tasks = [];
    state.task = null;
  },

  resetChosenTaskDate(state, value) {
    state.chosenTaskDate = value;
  },
};

export const getters = {
  tasks: (state) => state.tasks,
  task: (state) => state.task,
  transactionsStatuses: (state) => state.transactions.map((obj) => obj.status),
  taskDate: (state) => state.chosenTaskDate,
};

export const actions = {
  async getTasks({ commit }) {
    try {
      const resp = await this.$axios.$get(
        `${this.$config.API_SERVER_ETH}/api/task`
      );

      commit("saveTasks", resp);
      return resp;
    } catch (error) {
      console.error("Error getting tasks:", error);
      throw error;
    }
  },

  async getTask({ commit }, id) {
    try {
      const resp = await this.$axios.$get(
        `${this.$config.API_SERVER_ETH}/api/task/${id}`
      );

      commit("saveTask", resp);
      return resp;
    } catch (error) {
      console.error("Error getting task:", error);
      throw error;
    }
  },

  async getTaskTransactions({ commit }, id) {
    try {
      const resp = await this.$axios.$get(
        `${this.$config.API_SERVER_ETH}/api/task/${id}/completed`
      );

      commit("saveTransactions", resp);
      return resp;
    } catch (error) {
      console.error("Error getting task:", error);
      throw error;
    }
  },

  async setTask({ commit }, data) {
    try {
      const resp = await this.$axios.$post(
        `${this.$config.API_SERVER_ETH}/api/task`,
        data
      );

      this.$router.replace({
        name: "tasks-id",
        path: `/tasks/${resp.id}`,
        params: { id: `${resp.id}` },
      });

      commit("saveTask", resp);
      return resp;
    } catch (error) {
      console.error("Error setting task:", error);
      throw error;
    }
  },

  async updateTask({ commit }, data) {
    try {
      const id = data.taskId;
      if (!id) return;

      delete data.taskId;

      const resp = await this.$axios.$put(
        `${this.$config.API_SERVER_ETH}/api/task/${id}`,
        data
      );

      commit("saveTask", resp);
      return resp;
    } catch (error) {
      console.error("Error updating task:", error);
      throw error;
    }
  },

  async deleteTask({ commit }, id) {
    const resp = await this.$axios
      .$delete(`${this.$config.API_SERVER_ETH}/api/task/${id}`)
      .catch((err) =>
        err?.response?.data ? reject(err.response.data) : reject(err)
      );

    commit("deleteTask", id);
  },

  async setLogs({ commit }, data) {
    try {
      const id = data.dex_id;
      if (!id) return;

      delete data.dex_id;
      data.e_t_h_sign = data.ETHSign;
      delete data.ETHSign;

      const resp = await this.$axios.$put(
        `${this.$config.API_SERVER_ETH}/api/task/${id}`,
        data
      );

      this.$router.replace({
        name: "tasks-id",
        path: `/tasks/${resp.id}`,
        params: { id: `${resp.id}` },
      });

      commit("saveTask", resp);
      return resp;
    } catch (error) {
      console.error("Error setting logs:", error);
      throw error;
    }
  },

  resetTasksStore({ commit }) {
    commit("resetStore");
  },

  resetTaskDate({ commit }, data) {
    commit("resetChosenTaskDate", data);
  },
};
