
export default {
  props: ["error"],

  computed: {
    getData() {
      switch (this.error.statusCode) {
        case 404:
          return {
            image: "/img/404-new.png",
            title: "We’ve lost this page",
            text: "Sorry, the page you are looking for doesn't exist or has been moved",
          };
        case 500:
          return {
            image: "/img/500-new.png",
            title: "Internal Server Error",
            text: "Please try again later",
          };
        default:
          return {
            image: "/img/unknown-new.png",
            title: "Unknown Error",
            text: "An error occurred",
          };
      }
    },
  },

  methods: {
    backHome() {
      window.location.replace("/");
    },
  },
};
