const state = () => ({
  userBalance: null,
  userSubscription: null,
  plans: [],
  history: [],
  paymentId: null,
  planId: null,
  status: null,
  message: null,
});

const getters = {
  userBalance: (state) => state.userBalance,
  userSubscription: (state) => state.userSubscription,
  plans: (state) => state.plans,
  history: (state) => state.history,
  paymentId: (state) => state.paymentId,
  planId: (state) => state.planId,
  status: (state) => state.status,
  message: (state) => state.message,
};

const mutations = {
  setUserBalance(state, balance) {
    state.userBalance = balance.balance;
  },

  setSubscription(state, subscription) {
    state.userSubscription = subscription;
  },

  setAvailablePlans(state, plans) {
    state.plans = plans;
  },

  setHistory(state, history) {
    state.history = history;
  },

  setPaymentId(state, id) {
    state.paymentId = id;
  },

  setStatus(state, data) {
    state.planId = data.plan_id;
    state.status = data.status;
    state.message = data.message;
  },
};

const actions = {
  async getUserBalance({ commit }) {
    const resp = await this.$axios
      .$get(`${this.$config.API_SERVER_ETH}/api/users/balance`)
      .catch((err) =>
        err?.response?.data ? err?.response?.data : err.message
      );

    commit("setUserBalance", resp);
  },

  async getUserSubscription({ commit }) {
    const resp = await this.$axios
      .$get(`${this.$config.API_SERVER_ETH}/api/users/subscription`)
      .catch((err) =>
        err?.response?.data ? err?.response?.data : err.message
      );

    commit("setSubscription", resp);
  },

  async createUserSubscription({ commit }, id) {
    try {
      const resp = await this.$axios.$post(
        `${this.$config.API_SERVER_ETH}/api/users/subscription/${id}`
      );
      commit("setSubscription", resp);

      return resp;
    } catch (error) {
      console.error("Error creating plan:", error);
      throw error;
    }
  },

  async cancelUserSubscription({ commit }, id) {
    try {
      const resp = await this.$axios.$post(
        `${this.$config.API_SERVER_ETH}/api/users/subscription/${id}/cancel`
      );
      commit("setSubscription", resp);

      return resp;
    } catch (error) {
      console.error("Error cancelling plan:", error);
      throw error;
    }
  },

  async renewUserSubscription({ commit }, id) {
    try {
      const resp = await this.$axios.$post(
        `${this.$config.API_SERVER_ETH}/api/users/subscription/${id}/renew`
      );
      commit("setSubscription", resp);

      return resp;
    } catch (error) {
      console.error("Error renewing plan:", error);
      throw error;
    }
  },

  async getPlans({ commit }) {
    const resp = await this.$axios
      .$get(`${this.$config.API_SERVER_ETH}/api/plans`)
      .catch((err) =>
        err?.response?.data ? err?.response?.data : err.message
      );

    commit("setAvailablePlans", resp);
  },

  async getPaymentHistory({ commit }) {
    try {
      const resp = await this.$axios.$get(
        `${this.$config.API_SERVER_ETH}/api/payments`
      );
      commit("setHistory", resp);
      return resp;
    } catch (error) {
      console.error("Error getting history:", error);
      throw error;
    }
  },

  async sendPayment({ commit }, data) {
    try {
      const resp = await this.$axios.$post(
        `${this.$config.API_SERVER_ETH}/api/payments/url`,
        data
      );
      commit("setPaymentId", resp.id);
      return resp;
    } catch (error) {
      console.error("Error sending payment:", error);
      throw error;
    }
  },

  async getPaymentStatus({ commit, dispatch }, data) {
    try {
      const resp = await this.$axios.$get(
        `${this.$config.API_SERVER_ETH}/api/payments/status`,
        {
          params: {
            id: data.id,
            payment_id: data.payment_id,
          },
        }
      );

      commit("setStatus", resp);

      // if (
      //   resp.plan_id &&
      //   ["closed", "failed", "refunded", "expired"].includes(resp.status)
      // ) {
      //   await dispatch("createUserSubscription", resp.plan_id);
      // }

      return resp;
    } catch (error) {
      console.error("Error getting status:", error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
