
import User from "~/components/User";

export default {
  name: "BreadCrumbs",
  components: { User },

  props: {
    value: {
      type: String,
      default: () => "",
    },
    icon: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    reload: 0,
  }),

  computed: {
    breadCrumbs() {
      return this.$store.state.breadCrumbs;
    },

    context() {
      return this.breadCrumbs[this.$route.name];
    },

    isNesting() {
      return typeof this.context === "object";
    },
  },

  methods: {
    showContext() {
      return typeof this.context === "string"
        ? this.context
        : "title Not found";
    },
  },
};
