import { render, staticRenderFns } from "./Activity.vue?vue&type=template&id=6024a54c&scoped=true&lang=pug"
import script from "./Activity.vue?vue&type=script&lang=js"
export * from "./Activity.vue?vue&type=script&lang=js"
import style0 from "./Activity.vue?vue&type=style&index=0&id=6024a54c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6024a54c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Checkbox: require('/var/www/app.routs/components/Checkbox.vue').default,Select: require('/var/www/app.routs/components/Select.vue').default,Button: require('/var/www/app.routs/components/Button.vue').default,Input: require('/var/www/app.routs/components/Input.vue').default})
