export const Activity = () => import('../../components/Activity.vue' /* webpackChunkName: "components/activity" */).then(c => wrapFunctional(c.default || c))
export const Billing = () => import('../../components/Billing.vue' /* webpackChunkName: "components/billing" */).then(c => wrapFunctional(c.default || c))
export const BillingHistory = () => import('../../components/BillingHistory.vue' /* webpackChunkName: "components/billing-history" */).then(c => wrapFunctional(c.default || c))
export const BillingPaid = () => import('../../components/BillingPaid.vue' /* webpackChunkName: "components/billing-paid" */).then(c => wrapFunctional(c.default || c))
export const BreadCrumbs = () => import('../../components/BreadCrumbs.vue' /* webpackChunkName: "components/bread-crumbs" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Calendar = () => import('../../components/Calendar.vue' /* webpackChunkName: "components/calendar" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../components/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const ConnectedExchange = () => import('../../components/ConnectedExchange.vue' /* webpackChunkName: "components/connected-exchange" */).then(c => wrapFunctional(c.default || c))
export const DraggingDropping = () => import('../../components/DraggingDropping.vue' /* webpackChunkName: "components/dragging-dropping" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../../components/Input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const Logs = () => import('../../components/Logs.vue' /* webpackChunkName: "components/logs" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../components/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const Message = () => import('../../components/Message.vue' /* webpackChunkName: "components/message" */).then(c => wrapFunctional(c.default || c))
export const Onboard = () => import('../../components/Onboard.vue' /* webpackChunkName: "components/onboard" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const Profile = () => import('../../components/Profile.vue' /* webpackChunkName: "components/profile" */).then(c => wrapFunctional(c.default || c))
export const Radio = () => import('../../components/Radio.vue' /* webpackChunkName: "components/radio" */).then(c => wrapFunctional(c.default || c))
export const Recharge = () => import('../../components/Recharge.vue' /* webpackChunkName: "components/recharge" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const Select = () => import('../../components/Select.vue' /* webpackChunkName: "components/select" */).then(c => wrapFunctional(c.default || c))
export const Table = () => import('../../components/Table.vue' /* webpackChunkName: "components/table" */).then(c => wrapFunctional(c.default || c))
export const Textarea = () => import('../../components/Textarea.vue' /* webpackChunkName: "components/textarea" */).then(c => wrapFunctional(c.default || c))
export const Tooltip = () => import('../../components/Tooltip.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c))
export const User = () => import('../../components/User.vue' /* webpackChunkName: "components/user" */).then(c => wrapFunctional(c.default || c))
export const WalletServices = () => import('../../components/WalletServices.vue' /* webpackChunkName: "components/wallet-services" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
