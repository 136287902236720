import { ethers } from "~/plugins/ethers.min.js";

import login from "~/pages/login";
import Loading from "~/components/loading";
import Button from "~/components/Button";
import Input from "~/components/Input";
import Select from "~/components/Select";
import Checkbox from "~/components/Checkbox";
import Radio from "~/components/Radio";
import BreadCrumbs from "~/components/BreadCrumbs";
import Textarea from "~/components/Textarea";
import Pagination from "~/components/Pagination";
import Table from "~/components/Table";
import Message from "~/components/Message";
import Search from "~/components/Search";
import ConnectedExchange from "~/components/ConnectedExchange";
import Logs from "~/components/Logs";
import Tooltip from "~/components/Tooltip";
import Activity from "~/components/Activity";
import Calendar from "~/components/Calendar";
import WalletServices from "~/components/WalletServices";

export default {
  name: "default",
  components: {
    login,
    Loading,
    Button,
    Input,
    Select,
    Checkbox,
    Radio,
    BreadCrumbs,
    Textarea,
    Pagination,
    Table,
    Message,
    Search,
    ConnectedExchange,
    Logs,
    Tooltip,
    Activity,
    Calendar,
    WalletServices,
  },
  head() {
    return {
      title: `${this.titlePage} | Routs`,
    };
  },
  data: () => ({
    reloadGAZ: false,
    reloadTimeGAZ: 60000,
    reloadPage: false,
  }),
  computed: {
    // serverName() {
    //   const word = this.$config.API_SERVER_NAME.slice(0, this.$config.API_SERVER_NAME.lastIndexOf("."));
    //   return word[0].toUpperCase() + word.substr(1);
    // },
    titlePage() {
      return this.$route.name in this.breadCrumbs
        ? typeof this.breadCrumbs[this.$route.name] === "string"
          ? this.breadCrumbs[this.$route.name]
          : this.breadCrumbs[this.$route.name][1]
        : "Home";
    },
    breadCrumbs() {
      return this.$store.state.breadCrumbs;
    },
    isAuthenticated() {
      return this.$store.getters?.isAuthenticated;
    },
    theme() {
      return this.$store.state.switchTheme;
    },
    checkRelocatePage() {
      return (
        this.$route.name !== "reset_password" &&
        this.$route.name !== "confirm_email"
      );
    },
    wallets() {
      return this.$store.state.wallets;
    },
    path() {
      return this.$route.path;
    },
  },
  methods: {
    getPreferredScheme() {
      return window?.matchMedia?.("(prefers-color-scheme:dark)")?.matches
        ? "night"
        : "light";
    },
    switchingThem() {
      switch (this.getPreferredScheme()) {
        case "light":
          localStorage.setItem("theme", "light");
          this.$store.dispatch("switchTheme", true);
          break;
        case "night":
          localStorage.setItem("theme", "night");
          this.$store.dispatch("switchTheme", false);
          break;

        default:
          break;
      }
    },
    async setGAZ() {
      const ethRpc = [
        "https://eth.llamarpc.com",
        "https://ethereum.publicnode.com",
        "https://rpc.payload.de",
        "https://rpc.builder0x69.io",
        "https://eth.drpc.org",
        "https://eth.meowrpc.com",
      ];
      let index = 1;
      const provider = new ethers.JsonRpcProvider(ethRpc[index]);
      const feeData = await provider.getFeeData();
      this.$store.dispatch(
        "setGAZ",
        parseFloat(
          ethers.formatUnits(feeData.gasPrice || feeData.maxFeePerGas, "gwei")
        ).toFixed(2)
      );

      clearTimeout(this.reloadGAZ);
      this.reloadGAZ = false;
      this.reloadGAZ = setTimeout(this.setGAZ, this.reloadTimeGAZ); // reload GAZ
    },
    keyPress(e) {
      if (e.key === "F5" || e.key === "R") {
        this.reloadPage = true;
      } else {
        this.reloadPage = false;
      }
      console.log("keyPress", this.reloadPage);
    },
  },

  async created() {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", this.$config.GOOGLE_ANALYTICS_ID);

    this.setGAZ();

    const theme = localStorage.getItem("theme");

    try {
      if (this.isAuthenticated) {
        const token = localStorage
          .getItem("auth._token.local")
          .replace("Bearer ", "");

        await this.$store.dispatch("setToken", { token, token });
        /*window.routs.on("accountsChanged", () =>
          this.$store.dispatch("metaMeask")
        );*/
      }
    } catch (error) {
      console.log(error);
    }

    // if (!theme) {
    //   this.switchingThem();
    // } else {
    //   switch (theme) {
    //     case "light":
    //       this.$store.dispatch("switchTheme", true);
    //       break;
    //     case "night":
    //       this.$store.dispatch("switchTheme", false);
    //       break;

    //     default:
    //       break;
    //   }
    // }
  },
};
