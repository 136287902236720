
export default {
  name: "User",

  computed: {
    user() {
      return this.$store.state.auth?.user;
    },

    userColorLogo() {
      return this.user?.color?.split(",") ?? false;
    },
  },

  methods: {
    async logout() {
      await this.$auth.logout();
    },
  },
};
