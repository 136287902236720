import { render, staticRenderFns } from "./Table.vue?vue&type=template&id=d59c772a&scoped=true&lang=pug"
import script from "./Table.vue?vue&type=script&lang=js"
export * from "./Table.vue?vue&type=script&lang=js"
import style0 from "./Table.vue?vue&type=style&index=0&id=d59c772a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d59c772a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Checkbox: require('/var/www/app.routs/components/Checkbox.vue').default,Tooltip: require('/var/www/app.routs/components/Tooltip.vue').default,Button: require('/var/www/app.routs/components/Button.vue').default,ConnectedExchange: require('/var/www/app.routs/components/ConnectedExchange.vue').default,Input: require('/var/www/app.routs/components/Input.vue').default,Button: require('/var/www/app.routs/components/Button.vue').default,Table: require('/var/www/app.routs/components/Table.vue').default,Pagination: require('/var/www/app.routs/components/Pagination.vue').default})
