import cookies from "js-cookie";

export default function ({ store, redirect, $auth, route }) {
  const token = cookies.get("auth._token.local");
  const expiresIn = store.getters?.loggedInUser?.exp * 1000;

  if (token && route.path == "/") {
    return redirect("/tasks");
  }
  if (route.path !== "/login") {
    if (expiresIn + 10000 <= Date.now()) {
      store.dispatch("refreshToken").catch((err) => store.dispatch("logout"));
    }
  }
}
