
import User from "~/components/User";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Menu",
  components: { User },

  data: () => ({
    menuTop: [
      {
        name: "My Tasks",
        href: "/tasks",
        icon: "icon-list",
        subMenu: [{ name: "Add New", href: "/tasks/new" }],
      },
      {
        name: "Withdraw OKX",
        icon: "icon-deposit",
        href: "/cex",
        subMenu: [{ name: "API Keys", href: "/cex/api" }],
      },
      {
        name: "My Profile",
        icon: "icon-cog",
        href: "/profile",
        subMenu: [
          { name: "Add Wallets", href: "/profile/add-wallets" },
          { name: "Billing", href: "/profile/billing" },
        ],
      },
    ],
    isOpen: false,
  }),

  computed: {
    ...mapGetters("plans", ["userSubscription"]),

    currentUser() {
      return this.$store.state.auth?.user;
    },
  },

  methods: {
    ...mapActions("tasks", ["resetTasksStore"]),

    openHeader() {
      this.isOpen = !this.isOpen;
      document.documentElement.classList.toggle("lock");
    },

    closeHeader(isSubmenu = false) {
      this.isOpen = false;
      document.documentElement.classList.remove("lock");

      if (isSubmenu) this.resetTasksStore();
    },
  },
};
