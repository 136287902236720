
export default {
  name: "Button",
  props: {
    subData: {
      type: [String, Array, Object],
      default: () => "",
    },
    id: {
      type: String,
      default: "",
    },
    metaMask: {
      type: Boolean,
      default: false,
    },
    trustWallet: {
      type: Boolean,
      default: false,
    },
    okx: {
      type: Boolean,
      default: false,
    },
    rabby: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "normal",
    },
    icon: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    classList: {
      type: [String, Array, Object],
      default: () => "",
    },
  },

  computed: {
    hashGenerator() {
      return this.$store.getters.hashGenerator(8);
    },
  },

  methods: {
    submitEvent(e) {
      e.preventDefault();

      if (e.type in this._events) {
        e.subData = this.subData;
        this.$emit(e.type, e);
      }
    },
  },
};
